import './styles/index.scss';
import 'ol/ol.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { PARAM_CLIENT_ID, PARAM_PARENTORIGIN, PARAM_EMAIL } from './constants/network';

const location = window.location.toString();
const params = location.split('?');

let userMail;
let clientUUID;
let parentOrigin: string = '';

if (params.length > 1) {
  const values = params[1].split('&');

  values.forEach(param => {
    const paramPair = param.split('=', 2);
    const [key, value] = paramPair;

    switch (key.toLowerCase()) {
      case PARAM_CLIENT_ID:
        clientUUID = value;
        break;
      case PARAM_PARENTORIGIN:
        parentOrigin = value;
        break;
      case PARAM_EMAIL:
        userMail = value;
        break;
      default:
    }
  });
}

ReactDOM.render(
  <App clientUUID={clientUUID} userMail={userMail} parentOrigin={parentOrigin} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
