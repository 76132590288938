import * as React from 'react';

import { map } from 'lodash';

import { FEATURE_FIELD_NAME } from '../../../constants/constants';
import { AREA_LIST_TITLE, AREA_LIST_SEARCH_FIELD_HINT } from '../../../constants/labels';

import { AreaListContainerProps, AreaListContainerState } from '../../../@types/AreaList.d';
import { Area } from '../../../@types/Common.d';
import ListHeader from '../../common/listHeader/ListHeader';
import { sortAreasByAttribute } from '../../../util/sortUtil';
import AreaList from './AreaList';
import AreaListSortHeader from './AreaListSortHeader';

export default class AreaListContainer extends React.Component<
  AreaListContainerProps,
  AreaListContainerState
> {
  constructor(props: AreaListContainerProps) {
    super(props);

    const { historyTemplate } = this.props;
    const areas =
      historyTemplate?.locations.reduce(
        (acc, location) => [...acc, ...location.areas],
        [] as Area[]
      ) ?? [];

    this.state = {
      areas,
      filteredAreaList: areas,
      searchString: '',
      reinit: new Date().getTime()
    };

    this.filterAreaList = this.filterAreaList.bind(this);
    this.sortAreasByAttribute = this.sortAreasByAttribute.bind(this);
  }

  componentDidUpdate(prevProps: AreaListContainerProps) {
    const { historyTemplate } = this.props;

    if (
      JSON.stringify(
        historyTemplate?.locations.reduce(
          (acc, { areas }) => [...acc, ...areas.map(({ feature, layer, ...area }) => area)],
          [] as Area[]
        )
      ) !==
      JSON.stringify(
        prevProps.historyTemplate?.locations.reduce(
          (acc, { areas }) => [...acc, ...areas.map(({ feature, layer, ...area }) => area)],
          [] as Area[]
        )
      )
    ) {
      const { searchString } = this.state;

      const areas =
        historyTemplate?.locations.reduce(
          (acc, location) => [...acc, ...location.areas],
          [] as Area[]
        ) ?? [];

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          areas,
          filteredAreaList: areas,
          searchString: '',
          reinit: new Date().getTime()
        },
        () => {
          this.filterAreaList(searchString);
        }
      );
    }
  }

  filterAreaList(searchString: string) {
    const { areas } = this.state;

    let filteredAreaList;
    if (searchString.length <= 0) filteredAreaList = areas;
    else {
      const searchStringRegex = map(searchString.split(''), s => `[${s}]\\w*`).join('');
      const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
      filteredAreaList = areas.filter(
        ({ areaKey, feature }) =>
          areaKey?.match(regex) || feature?.get(FEATURE_FIELD_NAME).match(regex)
      );
    }

    this.setState({ filteredAreaList, searchString });
  }

  sortAreasByAttribute(attribute: string, desc: boolean, isMeta?: boolean) {
    const { filteredAreaList } = this.state;

    this.setState({
      filteredAreaList: sortAreasByAttribute(filteredAreaList, attribute, desc, isMeta)
    });
  }

  render() {
    const { showAreaList, hideAreaList, selectFeature } = this.props;
    const { searchString, filteredAreaList, reinit } = this.state;

    return (
      <div
        className={`d-flex flex-column collapse-content ${
          showAreaList ? 'shown' : 'hidden'
        } width list-menu-list-container`}
      >
        {showAreaList ? (
          <>
            <ListHeader
              hideList={hideAreaList}
              searchString={searchString}
              filterList={this.filterAreaList}
              listTitle={AREA_LIST_TITLE}
              searchHint={AREA_LIST_SEARCH_FIELD_HINT}
            />
            <AreaListSortHeader sortByAttribute={this.sortAreasByAttribute} reinit={reinit} />
            <AreaList areas={filteredAreaList} selectFeature={selectFeature} />
          </>
        ) : null}
      </div>
    );
  }
}
