import * as React from 'react';

import { ListGroup } from 'react-bootstrap';
import { map } from 'lodash';

import HistoryListItem from './HistoryListItem';

import { sortHistoryTemplates } from '../../util/sortUtil';

import { HistoryListPorps } from '../../@types/HistoryList.d';
import { HISTORY_LIST_EMPTY } from '../../constants/labels';

const HistoryList: React.FC<HistoryListPorps> = (props: HistoryListPorps) => {
  const { historyTemplates, applyHistoryTemplate, offer } = props;

  return (
    <div className="list-container">
      <ListGroup className="flex-list">
        {historyTemplates.length > 0 ? (
          map(sortHistoryTemplates(historyTemplates), historyTemplate => (
            <HistoryListItem
              key={historyTemplate.id}
              historyTemplate={historyTemplate}
              applyHistoryTemplate={applyHistoryTemplate}
              offer={offer}
            />
          ))
        ) : (
          <ListGroup.Item>{HISTORY_LIST_EMPTY(offer)}</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

export default HistoryList;
