import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ListSearch from './ListSearch';
import { ListHeaderPorps } from '../../../@types/App.d';

const ListHeader: React.FC<ListHeaderPorps> = (props: ListHeaderPorps) => {
  const { hideList, filterList, listTitle, searchHint, searchString } = props;

  const onClickClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    hideList();
  };

  const onKeyDownClose = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (event.keyCode === 13) hideList();
  };

  return (
    <div className="list-header">
      <Row className="no-gutters pb-1">
        <Col xs={11} className="align-self-center">
          <div className="caption">{listTitle}</div>
        </Col>
        <Col xs={1} className="align-self-center">
          <div
            role="button"
            className="hover-icon text-right close-button"
            tabIndex={0}
            onClick={onClickClose}
            onKeyDown={onKeyDownClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </Col>
      </Row>
      <Row className="no-gutters pt-1">
        <ListSearch filterList={filterList} searchHint={searchHint} searchString={searchString} />
      </Row>
    </div>
  );
};

export default ListHeader;
