import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { map } from 'lodash';

import CustomTooltip from '../../common/CustomTooltip';

import {
  AREA_LIST_EMPTY,
  TOOLTIP_AREA_SHOW,
  TOOLTIP_AREA_COMPLAINT_SEVERITY
} from '../../../constants/labels';

import { rgbTorgba } from '../../../util/featureStyle';

import {
  AreaListItemProps,
  AreaListProps,
  AreaListItemButtonsProps
} from '../../../@types/AreaList.d';

const AreaList: React.FC<AreaListProps> = (props: AreaListProps) => {
  const { areas, selectFeature } = props;

  return (
    <div className="list-container">
      <ListGroup className="flex-list">
        {areas ? (
          map(areas, area => (
            <AreaListItem key={area.areaKey} area={area} selectFeature={selectFeature} />
          ))
        ) : (
          <ListGroup.Item>{AREA_LIST_EMPTY}</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

const AreaListItem: React.FC<AreaListItemProps> = (props: AreaListItemProps) => {
  const { area, selectFeature } = props;

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();

    selectFeature(area, true);
  };

  return (
    <ListGroup.Item className="hover-item" onClick={onClick}>
      <Row className="no-gutters">
        <Col xs={3}>{area.areaKey}</Col>
        <Col xs={7}>{area.areaName ?? ''}</Col>
        <Col className="align-content-end">
          <AreaListItemButtons area={area} selectFeature={selectFeature} />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

const AreaListItemButtons: React.FC<AreaListItemButtonsProps> = (
  props: AreaListItemButtonsProps
) => {
  const { area, selectFeature } = props;

  const onClickFeature = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    selectFeature(area, true);
  };

  const onKeyDownFeature = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (event.keyCode === 13) selectFeature(area, true);
  };

  return (
    <Row className="no-gutters">
      <Col className="icon-col">
        <CustomTooltip
          tooltipId={`area-${area.areaKey}-color`}
          content={TOOLTIP_AREA_COMPLAINT_SEVERITY(area.areaMetaData.info['percentage'])}
        >
          <div
            className="color-square"
            style={{
              background: rgbTorgba(area.areaMetaData.areaStyle.fill) ?? '#000000'
            }}
          />
        </CustomTooltip>
      </Col>
      <Col className="icon-col">
        <CustomTooltip tooltipId={`area-${area.areaKey}-zoom`} content={TOOLTIP_AREA_SHOW}>
          <div
            role="button"
            tabIndex={0}
            onClick={onClickFeature}
            onKeyDown={onKeyDownFeature}
            className="hover-icon text-center"
          >
            <FontAwesomeIcon icon={faSearchLocation} />
          </div>
        </CustomTooltip>
      </Col>
    </Row>
  );
};

export default AreaList;
