import * as React from 'react';

import { Col, Row, ListGroup } from 'react-bootstrap';

import { SubsidiaryListItemProps } from '../../@types/Subsidiary.d';
import SubsidiaryListButtons from './SubsidiaryListButtons';

const SubsidiaryListItem: React.FC<SubsidiaryListItemProps> = (props: SubsidiaryListItemProps) => {
  const { subsidiary, zoomToSubsidiary } = props;

  const onClickItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <ListGroup.Item onClick={onClickItem} className="selectable hover-item">
      <Row className="no-gutters">
        <Col md={12}>{subsidiary.name}</Col>
        <Col md={12}>
          <SubsidiaryListButtons subsidiary={subsidiary} zoomToSubsidiary={zoomToSubsidiary} />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default SubsidiaryListItem;
