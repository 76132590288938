import * as React from 'react';

import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCrosshairs } from '@fortawesome/free-solid-svg-icons';

import AddressSearchField from './AddressSearchField';

import config from '../../config';
import {
  MAP_BUTTON_EXPORT,
  MAP_BUTTON_PRINT,
  MAP_BUTTON_SUBSIDIARIES_HIDE,
  MAP_BUTTON_SUBSIDIARIES_SHOW,
  EXPORT_TYPE_CSV,
  EXPORT_TYPE_EXCEL,
  MAP_BUTTON_ORDER_HISTORY_HIDE,
  MAP_BUTTON_ORDER_HISTORY_SHOW,
  MAP_BUTTON_AREAS
} from '../../constants/labels';

import {
  CenterLocationButtonProps,
  ExportButtonProps,
  FitSelectionButtonProps,
  PrintSelectionButtonProps,
  SubsidiariesButtonProps,
  MapMenuProps,
  MapMenuState,
  HistoryButtonProps,
  AreasButtonProps
} from '../../@types/MapButtons';
import { TRANSMISSION_TYPE_OFFER } from '../../constants/constants';

const ExportButton: React.FC<ExportButtonProps> = (props: ExportButtonProps) => {
  const { exportExcel, exportCSV } = props;

  const onClickExportCSV = (event: any) => {
    event.stopPropagation();

    exportCSV();
  };

  const onClickExportExcel = (event: any) => {
    event.stopPropagation();

    exportExcel();
  };

  return (
    <div className="underlay-background">
      <Dropdown>
        <Dropdown.Toggle id="export-dropdown" className="map-menu-button big">
          {MAP_BUTTON_EXPORT}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onClickExportCSV}>{EXPORT_TYPE_CSV}</Dropdown.Item>
          <Dropdown.Item onClick={onClickExportExcel}>{EXPORT_TYPE_EXCEL}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const FitSelectionButton: React.FC<FitSelectionButtonProps> = (
  props: FitSelectionButtonProps
) => {
  const { fitSelection } = props;

  const onClickFitButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    fitSelection();
  };
  return (
    <Button type="button" className="map-menu-button small" onClick={onClickFitButton}>
      <FontAwesomeIcon icon={faCrosshairs} />
    </Button>
  );
};

const PrintSelectionButton: React.FC<PrintSelectionButtonProps> = (
  props: PrintSelectionButtonProps
) => {
  const { printSelection } = props;

  const onClickPrint = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    printSelection();
  };

  return (
    <div className="underlay-background">
      <Button type="button" className="map-menu-button big" onClick={onClickPrint}>
        {MAP_BUTTON_PRINT}
      </Button>
    </div>
  );
};

const SubsidiariesButton: React.FC<SubsidiariesButtonProps> = (props: SubsidiariesButtonProps) => {
  const { toggleSubsidiaries, showSubsidiaryList } = props;
  const onClickShow = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    toggleSubsidiaries();
  };

  return (
    <div className="underlay-background">
      <Button type="button" className="map-menu-button big" onClick={onClickShow}>
        {showSubsidiaryList ? MAP_BUTTON_SUBSIDIARIES_HIDE : MAP_BUTTON_SUBSIDIARIES_SHOW}
      </Button>
    </div>
  );
};

const CenterCurrentLocationButton: React.FC<CenterLocationButtonProps> = (
  props: CenterLocationButtonProps
) => {
  const { centerLocation } = props;
  const onClickCenter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    centerLocation();
  };

  return (
    <div className="underlay-background center-container">
      <Button role="button" className="map-menu-button small center-button" onClick={onClickCenter}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </Button>
    </div>
  );
};

const HistoryButton: React.FC<HistoryButtonProps> = (props: HistoryButtonProps) => {
  const { toggleHistory, showHistory, transmissionType } = props;
  const onClickShow = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    toggleHistory();
  };

  return (
    <div className="underlay-background">
      <button type="button" className="map-menu-button big" onClick={onClickShow}>
        {showHistory
          ? MAP_BUTTON_ORDER_HISTORY_HIDE(transmissionType === TRANSMISSION_TYPE_OFFER)
          : MAP_BUTTON_ORDER_HISTORY_SHOW(transmissionType === TRANSMISSION_TYPE_OFFER)}
      </button>
    </div>
  );
};

const AreaButton: React.FC<AreasButtonProps> = (props: AreasButtonProps) => {
  const { toggleArea } = props;

  const onClickShow = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();

    toggleArea();
  };

  return (
    <div className="underlay-background">
      <button type="button" className="map-menu-button big" onClick={onClickShow}>
        {MAP_BUTTON_AREAS}
      </button>
    </div>
  );
};

export default class MapMenu extends React.Component<MapMenuProps, MapMenuState> {
  constructor(props: MapMenuProps) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      sideMenuExpanded,
      transmissionType,
      offerOrderSelected,
      centerLocation,
      fitSelection,
      showSubsidiaryList,
      showHistoryList,
      toggleSubsidiaries,
      toggleHistory,
      toggleArea,
      exportExcel,
      exportCSV,
      printSelection,
      zoomToAddress
    } = this.props;

    return (
      <>
        {config.map.buttons.fitSelection && (
          <div className="fit-selection-container ol-control ol-unselectable">
            <FitSelectionButton fitSelection={fitSelection} />
          </div>
        )}
        <div className="map-menu">
          <div className="half-container">
            <div
              className={`left  ${sideMenuExpanded ? 'side-menu-expanded' : 'side-menu-collapsed'}`}
            >
              {config.map.buttons.printMap && (
                <PrintSelectionButton printSelection={printSelection} />
              )}
              {config.map.buttons.showSubsidiaries && (
                <SubsidiariesButton
                  showSubsidiaryList={showSubsidiaryList}
                  toggleSubsidiaries={toggleSubsidiaries}
                />
              )}
              <HistoryButton
                transmissionType={transmissionType}
                showHistory={showHistoryList}
                toggleHistory={toggleHistory}
              />
              {config.map.buttons.exportSelection && (
                <ExportButton exportExcel={exportExcel} exportCSV={exportCSV} />
              )}
              {offerOrderSelected && <AreaButton toggleArea={toggleArea} />}
            </div>
          </div>
          <div className="spacer" />
          <div className="half-container">
            <div className="right">
              {config.map.buttons.searchAddress && (
                <AddressSearchField zoomToAddress={zoomToAddress} />
              )}
              {config.map.buttons.zoomToCurrentLocation && config.map.buttons.searchAddress && (
                <CenterCurrentLocationButton centerLocation={centerLocation} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
