export const OPENCAGE_API_KEY = 'b9f8d97a3b334d5fb8de17904ea5c052';
export const ORS_API_KEY = '5b3ce3597851110001cf62481720f3d229da4267b580a7b6c48f5ab5';

export const URL_OSM_SERVER = 'https://osm.freyplus.de/hot/{z}/{x}/{y}.png';
export const URL_NOMINATIM = 'https://api.opencagedata.com/geocode/v1/geojson';
export const URL_ORS = 'https://api.openrouteservice.org/v2/';
export const URL_GEOSERVER = (layerTitle: string) =>
  `https://geoserver.freyplus.de/geoserver/fpm/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=fpm%3A${layerTitle}&outputFormat=application%2Fjson`;

export const SERVICE_GEO_DATA = 'geoDataService/';
export const PATH_CLIENT_DATA = (clienUUID: string) => `${SERVICE_GEO_DATA}client/${clienUUID}`;

export const SERVICE_QM_DATA = 'qualityManagementService/';
export const PATH_GET_CLIENT_HISTORY = (clienUUID: string, order: boolean) =>
  `${SERVICE_QM_DATA}client/${clienUUID}/${order ? 'orders' : 'offers'}`;
export const PATH_GET_CLIENT_HISTORY_ITEM = (
  clienUUID: string,
  order: boolean,
  historyId: number
) => `${SERVICE_QM_DATA}client/${clienUUID}/${order ? 'order' : 'offer'}/${historyId}`;

export const PARAM_CLIENT_ID = 'clientid';
export const PARAM_PARENTORIGIN = 'parentorigin';
export const PARAM_EMAIL = 'email';
