import { Area, ClientLocation, HistoryTemplate } from '../@types/Common.d';

export const sortAreas = (areas: Area[]) => {
  return areas.sort((areaA: Area, areaB: Area) => {
    if (areaA.areaKey.toLocaleLowerCase() < areaB.areaKey.toLocaleLowerCase()) return -1;
    if (areaA.areaKey.toLocaleLowerCase() > areaB.areaKey.toLocaleLowerCase()) return 1;

    return 0;
  });
};

export const sortClientLocations = (subsidiaries: ClientLocation[]) => {
  return subsidiaries.sort((subsidiaryA: ClientLocation, subsidiaryB: ClientLocation) => {
    if (subsidiaryA.name.toLocaleLowerCase() < subsidiaryB.name.toLocaleLowerCase()) return -1;
    if (subsidiaryA.name.toLocaleLowerCase() > subsidiaryB.name.toLocaleLowerCase()) return 1;

    return 0;
  });
};

export const sortHistoryTemplates = (historyTemplates: HistoryTemplate[]) => {
  return historyTemplates.sort(
    (historyTemplateA: HistoryTemplate, historyTemplateB: HistoryTemplate) => {
      if (historyTemplateA.id < historyTemplateB.id) return 1;
      if (historyTemplateA.id > historyTemplateB.id) return -1;
      return 0;
    }
  );
};

export const sortAreasByAttribute = (
  areas: Area[],
  attribute: string,
  desc: boolean,
  isMeta?: boolean
) => {
  if (isMeta)
    return areas.sort((a1, a2) => {
      let v1: string | number = a1.areaMetaData.info[attribute];
      let v2: string | number = a2.areaMetaData.info[attribute];

      if (!Number.isNaN(+v1) || !Number.isNaN(+v2)) {
        v1 = +v1;
        v2 = +v2;
      }

      if (v1 < v2) return desc ? -1 : 1;
      if (v1 > v2) return desc ? 1 : -1;
      return 0;
    });

  return areas.sort((a1, a2) => {
    let v1 = a1[attribute as keyof Area];
    let v2 = a2[attribute as keyof Area];

    if (!Number.isNaN(+v1) || !Number.isNaN(+v2)) {
      v1 = +v1;
      v2 = +v2;
    }

    if (v1 < v2) return desc ? -1 : 1;
    if (v1 > v2) return desc ? 1 : -1;
    return 0;
  });
};
