import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SubsidiaryListButtonsProps } from '../../@types/Subsidiary.d';
import CustomTooltip from '../common/CustomTooltip';
import { TOOLTIP_SUBSIDIARY_SHOW } from '../../constants/labels';

const SubsidiaryListButtons: React.FC<SubsidiaryListButtonsProps> = (
  props: SubsidiaryListButtonsProps
) => {
  const { subsidiary, zoomToSubsidiary } = props;

  const onClickZoom = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    zoomToSubsidiary(subsidiary);
  };

  const onKeyDownZoom = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (event.keyCode === 13) zoomToSubsidiary(subsidiary);
  };

  return (
    <div>
      <Row className="no-gutters">
        <Col>
          <div className="separator" />
        </Col>
      </Row>
      <Row className="no-gutters justify-content-start">
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`subsidiary-${subsidiary.id}-zoom`}
            content={TOOLTIP_SUBSIDIARY_SHOW}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickZoom}
              onKeyDown={onKeyDownZoom}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </CustomTooltip>
        </Col>
      </Row>
    </div>
  );
};

export default SubsidiaryListButtons;
