import { map, compact } from 'lodash';
import { Feature } from 'ol';
import { Area } from '../@types/Common.d';

export const getAllAdditionalAreas = (areas?: Area[]) => {
  if (areas) return areas.flatMap(area => area.additionalAreas);
  return [] as Area[];
};

export const getAllAreaFeatures = (areas?: Area[]): Feature[] => [
  ...compact(map(areas, area => area.feature)),
  ...compact(getAllAdditionalAreas(areas).map(area => area?.feature))
];
