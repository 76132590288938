import { COMPLAINT_SEVERITY_THRESHOLD_LOW, COMPLAINT_SEVERITY_THRESHOLD_HIGHT } from './constants';

export const LOADING_PLEASE_WAIT: string = 'Bitte warten';
export const LOADING_PROCESS_REQUEST: string = 'Ihre Anfrage wird bearbeitet ...';
export const LOADING_LAYERS = (loadedLayers: number, totalLayers: number) =>
  `${loadedLayers} von ${totalLayers} geladen.`;

export const MAP_BUTTON_CENTER: string = 'Zentrieren';
export const MAP_BUTTON_EXPORT: string = 'Export';
export const MAP_BUTTON_FIT: string = 'Auswahl zentrieren';
export const MAP_BUTTON_IMPORT: string = 'Import';
export const MAP_BUTTON_PRINT: string = 'Karte drucken';
export const MAP_BUTTON_SUBSIDIARIES_SHOW: string = 'Filialen';
export const MAP_BUTTON_SUBSIDIARIES_HIDE: string = 'Filialen';
export const MAP_BUTTON_DISTRIBUTION_TEMPLATES_SHOW: string = 'Vorlagen';
export const MAP_BUTTON_DISTRIBUTION_TEMPLATES_HIDE: string = 'Vorlagen';
export const MAP_BUTTON_ORDER_HISTORY_SHOW = (offer: boolean) =>
  `${offer ? 'Angebote' : 'Aufträge'}`;
export const MAP_BUTTON_ORDER_HISTORY_HIDE = (offer: boolean) =>
  `${offer ? 'Angebote' : 'Aufträge'}`;
export const MAP_BUTTON_WEEKPART_WEEKEND: string = 'Wochenende';
export const MAP_BUTTON_WEEKPART_MIDWEEK: string = 'Wochenmitte';
export const MAP_BUTTON_WEEKPART_BEST: string = 'Optimal';
export const MAP_BUTTON_SEARCH_PLACEHOLDER: string = 'Suchbegriff eingeben...';
export const MAP_BUTTON_AREAS: string = 'Gebiete';

export const MAP_OVERLAY_CLIENT_LOCATION_NAME = 'Filiale';
export const MAP_OVERLAY_CLIENT_LOCATION_STREET = 'Straße';
export const MAP_OVERLAY_CLIENT_LOCATION_POSTCODE = 'Plz';
export const MAP_OVERLAY_CLIENT_LOCATION_PLACE = 'Name';

export const MAP_OVERLAY_AREA_NAME = 'Name';
export const MAP_OVERLAY_AREA_CIRCULATION = 'Auflage';
export const MAP_OVERLAY_POSTCODE = 'PLZ';

export const CIRCULATION_PIECES_SHORT: string = 'Stk.';
export const CIRCULATION_PIECES_LONG: string = 'Stück';

export const EXPORT_TYPE_CSV = 'CSV';
export const EXPORT_TYPE_EXCEL = 'Excel (.xlsx)';

export const HISTORY_TEMPLATE_LIST_TITLE = (offer: boolean) => `${offer ? 'Angebote' : 'Aufträge'}`;
export const HISTORY_TEMPLATE_LIST_SEARCH_FIELD_HINT = (offer: boolean) =>
  `${offer ? 'Angeots' : 'Auftrags'}historie suchen ...`;
export const HISTORY_TEMPLATE_LIST_ORDER_ID = (offer: boolean, number: number) =>
  `${offer ? 'Angeots' : 'Auftrags'}nummer: ${`000000${number}`.slice(-6)}`;
export const HISTORY_TEMPLATE_LIST_ORDER_LABEL = (date: string) => `Verteilung vom ${date}`;
export const HISTORY_TEMPLATE_LIST_ORDER_DISTRIBUTION_DATE = (date: string) =>
  `Verteiltag: ${date}`;
export const HISTORY_LIST_EMPTY = (offer: boolean) =>
  `Keine vergangenen ${offer ? 'Angebote' : 'Aufträge'} vorhanden`;

export const AREA_LIST_TITLE: string = 'Gebiete';
export const AREA_LIST_SEARCH_FIELD_HINT = 'Gebiet suchen ...';
export const AREA_LIST_EMPTY = 'Keine Gebiete vorhanden';

export const SUBSIDIARY_LIST_TITLE: string = 'Filialen';
export const SUBSIDIARY_LIST_NEW_SUBSIDIARY = 'Filiale hinzufügen';
export const SUBSIDIARY_LIST_SEARCH_FIELD_HINT = 'Filiale suchen ...';
export const SUBSIDIARY_LIST_EMPTY = 'Keine Filialen vorhanden';

export const TOOLTIP_SUBSIDIARY_SHOW = 'Gehe zu Filiale';
export const TOOLTIP_AREA_SHOW = 'Gehe zu Gebiet';
export const TOOLTIP_AREA_COMPLAINT_SEVERITY = (severity?: string) => {
  if (!severity) return 'Keine Reklamationen';

  const cSeverity = +severity.split('%')[0];

  if (cSeverity <= COMPLAINT_SEVERITY_THRESHOLD_LOW) return 'Reklamationen: NIEDRIG';
  if (
    cSeverity >= COMPLAINT_SEVERITY_THRESHOLD_LOW &&
    cSeverity < COMPLAINT_SEVERITY_THRESHOLD_HIGHT
  )
    return 'Reklamationen: MITTEL';
  if (cSeverity >= COMPLAINT_SEVERITY_THRESHOLD_HIGHT) return 'Reklamationen: HOCH';

  return 'Keine Reklamationen';
};
export const TOOLTIP_SORT_COMPLAINTS_TOTAL = 'Sortierung nach Reklamationen (total)';
export const TOOLTIP_SORT_COMPLAINTS_PERCENTAGE = 'Sortierung nach Reklamationen (prozentual)';
export const TOOLTIP_SORT_AREA_NAME = 'Sortierung nach Name';
export const TOOLTIP_SORT_AREA_KEY = 'Sortierung nach Postleitzahl';
export const TOOLTIP_SORT_CIRCULATION = 'Sortierung nach Auflage';
