import '../../styles/ListMenu.scss';

import * as React from 'react';
import { map } from 'lodash';

import HistoryList from './HistoryList';

import ListHeader from '../common/listHeader/ListHeader';
import {
  HISTORY_TEMPLATE_LIST_TITLE,
  HISTORY_TEMPLATE_LIST_SEARCH_FIELD_HINT
} from '../../constants/labels';
import { TRANSMISSION_TYPE_OFFER } from '../../constants/constants';

import { HistoryListContainerPorps, HistoryListContainerState } from '../../@types/HistoryList.d';

export default class HistoryContainer extends React.Component<
  HistoryListContainerPorps,
  HistoryListContainerState
> {
  constructor(props: HistoryListContainerPorps) {
    super(props);

    const { historyTemplates } = this.props;
    this.state = { filteredHistoryTemplateList: historyTemplates, searchString: '' };

    this.filterHistoryTemplateList = this.filterHistoryTemplateList.bind(this);
  }

  componentDidUpdate(prevProps: HistoryListContainerPorps) {
    const { historyTemplates } = this.props;

    if (
      JSON.stringify(historyTemplates.map(({ locations, ...history }) => history)) !==
      JSON.stringify(prevProps.historyTemplates.map(({ locations, ...history }) => history))
    ) {
      const { searchString } = this.state;
      this.filterHistoryTemplateList(searchString);
    }
  }

  filterHistoryTemplateList(searchString: string) {
    const { historyTemplates } = this.props;
    let filteredHistoryTemplateList;
    if (searchString.length <= 0) filteredHistoryTemplateList = historyTemplates;
    else {
      const searchStringRegex = map(searchString.split(''), s => `[${s}]\\w*`).join('');
      const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
      filteredHistoryTemplateList = historyTemplates.filter(
        ({ actionName, distributionDay }) =>
          actionName?.match(regex) || distributionDay.match(regex)
      );
    }

    this.setState({ filteredHistoryTemplateList, searchString });
  }

  render() {
    const {
      showHistoryTemplates,
      hideHistoryList,
      applyHistoryTemplate,
      transmissionType
    } = this.props;
    const { filteredHistoryTemplateList, searchString } = this.state;
    return (
      <div
        className={`d-flex flex-column collapse-content ${
          showHistoryTemplates ? 'shown' : 'hidden'
        } width list-menu-list-container`}
      >
        {showHistoryTemplates
          ? [
              <ListHeader
                key={1}
                hideList={hideHistoryList}
                searchString={searchString}
                filterList={this.filterHistoryTemplateList}
                listTitle={HISTORY_TEMPLATE_LIST_TITLE(
                  transmissionType === TRANSMISSION_TYPE_OFFER
                )}
                searchHint={HISTORY_TEMPLATE_LIST_SEARCH_FIELD_HINT(
                  transmissionType === TRANSMISSION_TYPE_OFFER
                )}
              />,
              <HistoryList
                key={2}
                offer={transmissionType === TRANSMISSION_TYPE_OFFER}
                historyTemplates={filteredHistoryTemplateList}
                applyHistoryTemplate={applyHistoryTemplate}
              />
            ]
          : null}
      </div>
    );
  }
}
