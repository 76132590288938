import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';

import {
  HISTORY_TEMPLATE_LIST_ORDER_LABEL,
  HISTORY_TEMPLATE_LIST_ORDER_DISTRIBUTION_DATE,
  HISTORY_TEMPLATE_LIST_ORDER_ID
} from '../../constants/labels';

import { HistoryListItemPorps } from '../../@types/HistoryList.d';

const HistoryListItem: React.FC<HistoryListItemPorps> = (props: HistoryListItemPorps) => {
  const { historyTemplate, applyHistoryTemplate, offer } = props;

  const onClickItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();

    applyHistoryTemplate(historyTemplate.selected ? undefined : historyTemplate);
  };

  return (
    <ListGroup.Item
      onClick={onClickItem}
      className="hover-item selectable"
      active={historyTemplate.selected}
    >
      <Row className="no-gutters">
        <Col className="text-bold">
          {historyTemplate.actionName ??
            HISTORY_TEMPLATE_LIST_ORDER_LABEL(historyTemplate.distributionDay)}
        </Col>
      </Row>
      <Row className="no-gutters subtitle">
        <Col>
          <small>{HISTORY_TEMPLATE_LIST_ORDER_ID(offer, historyTemplate.id)}</small>
        </Col>
      </Row>
      <Row className="no-gutters subtitle">
        <Col>
          <small>
            {HISTORY_TEMPLATE_LIST_ORDER_DISTRIBUTION_DATE(historyTemplate.distributionDay)}
          </small>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default HistoryListItem;
