import moment from 'moment';
import { map, uniqBy } from 'lodash';

import {
  Client,
  ClientLocation,
  ColorStyle,
  ClientLayer,
  DistributionTemplateLocation,
  OrderHistoryTemplate,
  OfferHistoryTemplate,
  AreaMetaData,
  Area
} from '../@types/Common.d';
import {
  AREA_SORT_ATTR_COMPLAINTS_PERCENTAGE,
  AREA_SORT_ATTR_COMPLAINTS_TOTAL
} from '../constants/constants';

export function extractLocality(responseLocality: any) {
  // TODO
  const {} = responseLocality;
}

export function extractLocalities(responseLocalities: any) {
  // TODO
  return map(responseLocalities, responseLocality => extractLocality(responseLocality));
}

export function extractArea(responseArea: any) {
  const { id, areaName, areaKey, circulation, type, countryCode, areaMetaData } = responseArea;

  const areaMetaDataCopy = { ...areaMetaData } as AreaMetaData;

  areaMetaDataCopy.info[AREA_SORT_ATTR_COMPLAINTS_PERCENTAGE] = (
    +areaMetaData.info[AREA_SORT_ATTR_COMPLAINTS_TOTAL] / +circulation
  ).toLocaleString('en-us', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return {
    id,
    areaName,
    areaKey,
    circulation,
    areaMetaData: areaMetaDataCopy,
    type,
    countryCode
  } as Area;
}

export function extractAreas(responseAreas: any) {
  // TODO temp fix
  return uniqBy(
    map(responseAreas, responseArea => extractArea(responseArea)),
    'areaKey'
  );
}

export function extractClientLocation(responseClientLocation: any) {
  const {
    addressName,
    city,
    housenumber,
    id,
    poi,
    lat,
    lon,
    name,
    number,
    postcode,
    street
  } = responseClientLocation;

  return {
    addressName,
    city,
    housenumber,
    id,
    poi: { active: poi?.active, inactive: poi?.inactive, name: poi?.name },
    lat,
    lon,
    name,
    number,
    postcode,
    street,
    areas: [] as Area[]
  } as ClientLocation;
}

export function extractClientLocations(responseClientLocations: any) {
  return map(responseClientLocations, (responseClientLocation: any) =>
    extractClientLocation(responseClientLocation)
  );
}

export function extractClientLayer(responseClientLayer: any) {
  const layerColor = {
    fill: responseClientLayer.colorFill,
    fillSelected: responseClientLayer.colorSelectedFill,
    stroke: responseClientLayer.colorStroke,
    strokeSelected: responseClientLayer.colorSelectedStroke,
    strokeWidth: responseClientLayer.strokeWidth
  } as ColorStyle;
  const { countryCode, state, type } = responseClientLayer;

  const title = `${countryCode.toUpperCase()}_${
    // eslint-disable-next-line prefer-template
    state !== '' ? `${state.toUpperCase() + '_'}` : ''
  }${type.toUpperCase()}`;

  return {
    layerColor,
    countryCode,
    state,
    type,
    title,
    loaded: false
  } as ClientLayer;
}

export function extractClientLayers(responseClientLayers: any) {
  return map(responseClientLayers, (responseClientLayer: any) =>
    extractClientLayer(responseClientLayer)
  );
}

export function extractClient(responseClient: any) {
  const { id, uuid, areaLayer, locations, name, transmissionType } = responseClient;

  return {
    id,
    uuid,
    name,
    transmissionType,
    clientLocations: extractClientLocations(locations),
    clientLayers: extractClientLayers(areaLayer),
    history: []
  } as Client;
}

export function extractDistributionTemplateLocation(responseDistributionTemplateLocation: any) {
  const { areas, locationId, locationSrcId, id } = responseDistributionTemplateLocation;
  return {
    locationId: locationId ?? locationSrcId ?? id,
    areas: extractAreas(areas)
  } as DistributionTemplateLocation;
}

export function extractDistributionTemplateLocations(responseDistributionTemplateLocations: any) {
  return map(responseDistributionTemplateLocations, (responseDistributionTemplateLocation: any) =>
    extractDistributionTemplateLocation(responseDistributionTemplateLocation)
  );
}

export function extractOrderTemplate(responseOrderTemplate: any) {
  const {
    id,
    actionName,
    distributionDay,
    locations,
    circulation,
    company,
    copiesToSubsidiary,
    email,
    prename,
    lastname,
    message,
    phone,
    productId,
    salutation,
    weekpart
  } = responseOrderTemplate;
  return {
    id,
    actionName: actionName.length > 0 ? actionName : undefined,
    distributionDay: moment(distributionDay).format('DD.MM.YYYY'),
    locations: extractDistributionTemplateLocations(locations),
    selected: false,
    circulation,
    company,
    copiesToSubsidiary,
    email,
    prename,
    lastname,
    message,
    phone,
    productId,
    salutation,
    weekpart
  } as OrderHistoryTemplate;
}

export function extractOrderTemplates(responseOrderTemplates: any) {
  return map(responseOrderTemplates, (responseOrderTemplate: any) =>
    extractOrderTemplate(responseOrderTemplate)
  );
}

export function extractOfferTemplate(responseOrderTemplate: any) {
  const {
    id,
    actionName,
    distributionDay,
    locations,
    circulation,
    company,
    copiesToSubsidiary,
    email,
    prename,
    lastname,
    message,
    phone,
    productId,
    salutation,
    weekpart
  } = responseOrderTemplate;
  return {
    id,
    actionName: actionName.length > 0 ? actionName : undefined,
    distributionDay: moment(distributionDay).format('DD.MM.YYYY'),
    locations: extractDistributionTemplateLocations(locations),
    selected: false,
    circulation,
    company,
    copiesToSubsidiary,
    email,
    prename,
    lastname,
    message,
    phone,
    productId,
    salutation,
    weekpart
  } as OfferHistoryTemplate;
}

export function extractOfferTemplates(responseOfferTemplates: any) {
  return map(responseOfferTemplates, (responseOfferTemplate: any) =>
    extractOfferTemplate(responseOfferTemplate)
  );
}
