import { transform as projTransform } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';

import { COORD_EPSG_4326, COORD_EPSG_3857, FEATURE_TYPE_LOCATION } from '../constants/constants';

import { generateLocationStyle } from './featureStyle';

import { ClientLocation } from '../@types/Common.d';

export const getLocationFeature = (location: ClientLocation) => {
  const poiIcon = location.poi.acive ?? require('../resources/img/POI.png');
  const locationFeature = new Feature({
    geometry: new Point(
      projTransform([location.lon, location.lat], COORD_EPSG_4326, COORD_EPSG_3857)
    ),
    type: FEATURE_TYPE_LOCATION,
    name: location.name,
    street: location.street,
    housenumber: location.housenumber,
    postcode: location.postcode,
    city: location.city
  });

  locationFeature.setStyle(generateLocationStyle(poiIcon));
  return locationFeature;
};

export const getLocationFeatures = (locations: ClientLocation[]) =>
  locations.map(location => getLocationFeature(location));
