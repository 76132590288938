import axios from 'axios';
import { map } from 'lodash';

import config from '../config';

import {
  PATH_CLIENT_DATA,
  PATH_GET_CLIENT_HISTORY,
  PATH_GET_CLIENT_HISTORY_ITEM,
  URL_NOMINATIM,
  OPENCAGE_API_KEY
} from '../constants/network';

import {
  extractClient,
  extractOfferTemplates,
  extractOrderTemplates,
  extractOfferTemplate,
  extractOrderTemplate
} from './responseUtil';

import { COUNTRY_CODE_DE } from '../constants/constants';

import { Client, Address, Coordinates } from '../@types/Common.d';

export const axiosClient = axios.create({
  baseURL: config.general.apiUrl,
  responseType: 'json'
});

const headers = {
  'X-API-Key-Token': config.general.apiKey ?? '',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache'
};

export const printError = (error: any) => {
  // eslint-disable-next-line no-console
  if (config.general.printToConsole) console.log(error);
};

export const getClientData = (clientUUID: string) =>
  axiosClient
    .get(PATH_CLIENT_DATA(clientUUID), {
      headers
    })
    .then(
      response => {
        return extractClient(response.data);
      },
      error => printError(error)
    )
    .catch(error => printError(error));

export const getHistoryData = (client: Client, order: boolean) =>
  axiosClient
    .get(PATH_GET_CLIENT_HISTORY(client.uuid, order), {
      headers
    })
    .then(
      response =>
        order ? extractOfferTemplates(response.data) : extractOrderTemplates(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const getHistoryItemData = (client: Client, order: boolean, histroyId: number) =>
  axiosClient
    .get(PATH_GET_CLIENT_HISTORY_ITEM(client.uuid, order, histroyId), {
      headers
    })
    .then(
      response =>
        order ? extractOfferTemplate(response.data) : extractOrderTemplate(response.data),
      error => printError(error)
    )
    .catch(error => printError(error));

export const searchAddress = (addressString: string) =>
  axiosClient
    .get(URL_NOMINATIM, {
      params: {
        q: addressString,
        key: OPENCAGE_API_KEY,
        language: COUNTRY_CODE_DE.toLowerCase(),
        no_annotations: 1,
        min_confidence: 5,
        countrycode: `${COUNTRY_CODE_DE.toLowerCase()}}`
      }
    })
    .then(
      response => {
        return map(response.data.features, address => {
          const coordinates: Coordinates = {
            lat: address.geometry.coordinates[1],
            lon: address.geometry.coordinates[0]
          };
          return { coordinates, name: address.properties.formatted, selected: false };
        }) as Address[];
      },
      error => printError(error)
    )
    .catch(error => printError(error));
