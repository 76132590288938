import * as React from 'react';
import { FormControl, Row, ListGroup, Col } from 'react-bootstrap';
import { map } from 'lodash';

import { MAP_BUTTON_SEARCH_PLACEHOLDER } from '../../constants/labels';
import { AddressSearchFieldProps, AddressSearchFieldState } from '../../@types/MapButtons';
import { Address } from '../../@types/Common';
import { searchAddress } from '../../util/api';

export default class AddressSearchField extends React.Component<
  AddressSearchFieldProps,
  AddressSearchFieldState
> {
  constructor(props: AddressSearchFieldProps) {
    super(props);

    this.state = {
      searchString: '',
      searchResults: [] as Address[],
      cursorPosition: -1
    };

    this.onBlur = this.onBlur.bind(this);
    this.onClickListItem = this.onClickListItem.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDownInput = this.onKeyDownInput.bind(this);

    this.searchAddress = this.searchAddress.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  onBlur(event: React.FocusEvent<HTMLInputElement>) {
    event.stopPropagation();
    this.setState({ searchResults: [] as Address[], cursorPosition: -1 });
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    const searchString = event.currentTarget.value || '';
    this.setState({ searchString, cursorPosition: -1 }, () => {
      this.searchAddress(searchString);
      this.setState({
        searchResults: [] as Address[],
        cursorPosition: -1
      });
    });
  }

  onClickListItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, address: Address) {
    event.stopPropagation();
    this.selectItem(address);
  }

  onKeyDownInput(event: React.KeyboardEvent<HTMLInputElement>) {
    event.stopPropagation();
    const { cursorPosition, searchResults } = this.state;

    if (event.keyCode === 38 && cursorPosition > 0) {
      this.setState(prevState => ({ cursorPosition: prevState.cursorPosition - 1 }));
    } else if (event.keyCode === 40 && cursorPosition < searchResults.length - 1) {
      this.setState(prevState => ({ cursorPosition: prevState.cursorPosition + 1 }));
    } else if (event.keyCode === 13 && searchResults.length > 0) {
      this.selectItem(searchResults[cursorPosition === -1 ? 0 : cursorPosition]);
    }
  }

  selectItem(address: Address) {
    const { zoomToAddress } = this.props;
    zoomToAddress(address);
    this.setState({
      searchResults: [] as Address[],
      cursorPosition: -1,
      searchString: address.name
    });
  }

  async searchAddress(searchString: string) {
    if (searchString.length < 2) return;
    const searchResults = (await searchAddress(searchString)) as Address[];

    this.setState({ searchResults, cursorPosition: -1 });
  }

  render() {
    const { searchResults, cursorPosition, searchString } = this.state;

    return (
      <div className="address-search-container underlay-background">
        <Row className="no-gutters address-search-bar">
          <Col sm={12} className="p-0">
            <FormControl
              className="type-search"
              type="search"
              value={searchString}
              placeholder={MAP_BUTTON_SEARCH_PLACEHOLDER}
              onChange={this.onChange}
              onKeyDown={this.onKeyDownInput}
            />
          </Col>
        </Row>
        <Row className="no-gutters address-results">
          {searchResults.length > 0 && (
            <Col sm={12} className="p-0">
              <ListGroup>
                {map(searchResults, (searchResult, i) => (
                  <ListGroup.Item
                    key={searchResult.name}
                    className="selectable search-result-item"
                    active={cursorPosition === i}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                      this.onClickListItem(event, searchResult)
                    }
                  >
                    {searchResult.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
