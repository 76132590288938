import '../styles/App.scss';

import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
import { findIndex } from 'lodash';

import MapComponent from './map/MapComponent';
import LoadingOverlay from './common/CustomLoadingOverlay';
import MapMenu from './mapControls/MapButtons';
import SubsidiaryListContainer from './subsidiaryList/SubsidiaryListContainer';
import HistoryContainer from './historyList/HistoryListContainer';
import Infobox from './map/infobox/Infobox';
import AreaListContainer from './map/areas/AreaListContainer';

import {
  REQUEST_IDENTIFIER_GET_CLIENT_DATA,
  REQUEST_IDENTIFIER_GET_CLIENT_HISTORY,
  REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM,
  TRANSMISSION_TYPE_ORDER,
  FEATURE_FIELD_SELECTED,
  FEATURE_FIELD_TYPE,
  LAYER_TYPE_DISTRICT
} from '../constants/constants';
import { LOADING_PLEASE_WAIT, LOADING_PROCESS_REQUEST } from '../constants/labels';
import { getClientData, getHistoryData, getHistoryItemData } from '../util/api';
import { getAllAreaFeatures } from '../util/areaUtil';
import { setFeatureNotSelected, setFeatureSelected } from '../util/featureStyle';

import { AppProps, AppState } from '../@types/App.d';
import {
  Client,
  OrderHistoryTemplate,
  OfferHistoryTemplate,
  Coordinates,
  ClientLocation,
  Address,
  Area
} from '../@types/Common.d';

export default class App extends React.Component<AppProps, AppState> {
  private fullScreenContainerRef = React.createRef<any>();

  private mapComponentRef = React.createRef<MapComponent>();

  constructor(props: AppProps) {
    super(props);

    this.state = {
      currentHistoryId: -1,
      isFullscreen: false,
      isLoading: false,
      pendingRequests: [],
      showHistoryList: false,
      showSubsidiaryList: false,
      showAreaList: false
    };

    this.applyHistoryTemplate = this.applyHistoryTemplate.bind(this);
    this.centerLocation = this.centerLocation.bind(this);
    this.changeFullscreen = this.changeFullscreen.bind(this);
    this.enableLoadingOverlay = this.enableLoadingOverlay.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.fitSelection = this.fitSelection.bind(this);
    this.getClientHistory = this.getClientHistory.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.selectFeature = this.selectFeature.bind(this);
    this.showAreaList = this.showAreaList.bind(this);
    this.showHistoryList = this.showHistoryList.bind(this);
    this.showSubsidiaryList = this.showSubsidiaryList.bind(this);
    this.toggleHistoryList = this.toggleHistoryList.bind(this);
    this.toggleSubsidiaryList = this.toggleSubsidiaryList.bind(this);
    this.toggleAreaList = this.toggleAreaList.bind(this);
    this.zoomToAddress = this.zoomToAddress.bind(this);
    this.zoomToSubsidiary = this.zoomToSubsidiary.bind(this);
  }

  async componentDidMount() {
    const { clientUUID } = this.props;

    window.addEventListener('message', () => {
      // TODO
    });

    if (clientUUID) {
      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
      const client = (await getClientData(clientUUID)) as Client;

      if (client) {
        this.setState({
          client
        });

        this.getClientHistory();
      }

      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
    }
  }

  async getClientHistory() {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY);

    const order = client?.transmissionType === TRANSMISSION_TYPE_ORDER;

    const res = await getHistoryData(client, order);
    let history;

    if (!Number.isNaN(+res)) history = [] as OrderHistoryTemplate[];
    else if (order) history = res as OrderHistoryTemplate[];
    else history = res as OfferHistoryTemplate[];

    client.history = history;

    this.setState({ client }, () =>
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY)
    );
  }

  async applyHistoryTemplate(historyTemplate?: OrderHistoryTemplate | OfferHistoryTemplate) {
    const { client, currentHistoryId } = this.state;

    if (!client) return;

    client.history.forEach(history => (history.selected = false));

    if (!historyTemplate) {
      const prevHistoryId = currentHistoryId;
      this.setState({ client, currentHistoryId: -1, selectedArea: undefined }, () => {
        const { current } = this.mapComponentRef;
        if (current)
          current.removeMetaData(
            client?.history.find(item => item.id === prevHistoryId)?.locations
          );
      });

      return;
    }

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM);

    const order = client?.transmissionType === TRANSMISSION_TYPE_ORDER;
    const historyIndex = findIndex(client.history, { id: historyTemplate.id });

    if (client.history[historyIndex].locations.length === 0) {
      const res = await getHistoryItemData(client, order, historyTemplate.id);
      let historyItem;

      if (!Number.isNaN(+res)) {
        // TODO error
        historyItem = {} as OrderHistoryTemplate;
      } else if (order) historyItem = res as OrderHistoryTemplate;
      else historyItem = res as OfferHistoryTemplate;

      client.history[historyIndex] = historyItem;
    }

    client.history[historyIndex].selected = true;

    this.setState({ client, currentHistoryId: historyTemplate.id }, () => {
      // this.showHistoryTemplateOnMap(client.history[historyIndex]);
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM);
    });
  }

  changeFullscreen(isFullscreen: boolean) {
    this.setState({
      isFullscreen
    });
  }

  async centerLocation() {
    const currentMapComponent = this.mapComponentRef.current;

    if (navigator.geolocation)
      await navigator.geolocation.getCurrentPosition(
        location => {
          const lat = location.coords.latitude;
          const lon = location.coords.longitude;
          const coordinates = { lon, lat } as Coordinates;

          if (currentMapComponent !== null) {
            currentMapComponent.zoomToCoordinates(coordinates);
          }
        },
        // eslint-disable-next-line no-console
        error => console.log('error', error)
      );
  }

  exportCSV() {
    // TODO
  }

  exportExcel() {
    // TODO
  }

  fitSelection() {
    const { client, currentHistoryId } = this.state;

    const { current } = this.mapComponentRef;

    if (current !== null)
      current.fitHistoryFeatures(client?.history.find(history => history.id === currentHistoryId));
  }

  enableLoadingOverlay(loading: boolean, requestIdentifier: string) {
    const { pendingRequests } = this.state;

    let nPendingRequests = [] as string[];

    if (loading) nPendingRequests = [requestIdentifier, ...pendingRequests];
    else {
      const index = pendingRequests.indexOf(requestIdentifier);
      if (index < 0) nPendingRequests = pendingRequests;
      else {
        nPendingRequests = [
          ...pendingRequests.slice(0, index),
          ...pendingRequests.slice(index + 1)
        ];
      }
    }

    this.setState({
      pendingRequests: nPendingRequests,
      isLoading: nPendingRequests.length > 0
    });
  }

  printSelection() {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    // TODO
    current.printSelection(getAllAreaFeatures([]));
  }

  selectFeature(area: Area, zoomToFeature?: boolean) {
    const { feature } = area;

    if (!feature) return;

    if (feature.get(FEATURE_FIELD_SELECTED)) {
      setFeatureNotSelected(feature, area.areaMetaData.areaStyle);

      feature.set(FEATURE_FIELD_SELECTED, false);
    } else if (!area.feature?.get(FEATURE_FIELD_SELECTED)) {
      setFeatureSelected(feature, area.areaMetaData.areaStyle);

      feature.set(FEATURE_FIELD_SELECTED, true);

      const { current } = this.mapComponentRef;

      if (zoomToFeature && current !== null)
        current.zoomToArea(feature, area.feature?.get(FEATURE_FIELD_TYPE) === LAYER_TYPE_DISTRICT);
    }

    const { selectedArea } = this.state;

    if (selectedArea?.feature) {
      setFeatureNotSelected(selectedArea.feature, selectedArea.areaMetaData.areaStyle);
      selectedArea.feature.set(FEATURE_FIELD_SELECTED, false);
    }

    this.setState({
      selectedArea: area?.feature?.get(FEATURE_FIELD_SELECTED) ? area : undefined
    });
  }

  showSubsidiaryList(show: boolean) {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showSubsidiaryList: show,
      showHistoryList: false,
      showAreaList: false
    });

    current.adjustMapSize();
  }

  showHistoryList(show: boolean) {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showHistoryList: show,
      showSubsidiaryList: false,
      showAreaList: false
    });

    current.adjustMapSize();
  }

  showAreaList(show: boolean) {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showHistoryList: false,
      showSubsidiaryList: false,
      showAreaList: show
    });

    current.adjustMapSize();
  }

  toggleSubsidiaryList() {
    const { showSubsidiaryList } = this.state;

    this.showSubsidiaryList(!showSubsidiaryList);
  }

  toggleHistoryList() {
    const { showHistoryList } = this.state;

    this.showHistoryList(!showHistoryList);
  }

  toggleAreaList() {
    const { showAreaList } = this.state;

    this.showAreaList(!showAreaList);
  }

  zoomToSubsidiary(subsidiary: ClientLocation) {
    const currentMapComponent = this.mapComponentRef.current;
    if (currentMapComponent !== null) {
      const { lon, lat } = subsidiary;
      currentMapComponent.zoomToCoordinates({ lat, lon });
    }
  }

  zoomToAddress(address: Address) {
    const currentMapComponent = this.mapComponentRef.current;
    if (currentMapComponent !== null) {
      const { lon, lat } = address.coordinates;
      currentMapComponent.zoomToCoordinates({ lat, lon });
    }
  }

  render() {
    const {
      isFullscreen,
      isLoading,
      client,
      selectedArea,
      showSubsidiaryList,
      showHistoryList,
      currentHistoryId,
      showAreaList
    } = this.state;

    return (
      <div className="h-100 w-100">
        <Row
          ref={this.fullScreenContainerRef}
          id="mapFullscreenContainer"
          className="h-100 w-100 no-gutters"
        >
          {isLoading && (
            <LoadingOverlay
              loadingTitle={LOADING_PLEASE_WAIT}
              loadingSubtitle={LOADING_PROCESS_REQUEST}
            />
          )}
          {client?.clientLocations && (
            <SubsidiaryListContainer
              subsidiaries={client.clientLocations}
              showSubsidiaryList={showSubsidiaryList}
              hideSubsidiaryList={this.showSubsidiaryList}
              zoomToSubsidiary={this.zoomToSubsidiary}
            />
          )}
          {client?.history && (
            <HistoryContainer
              transmissionType={client.transmissionType}
              historyTemplates={client.history}
              applyHistoryTemplate={this.applyHistoryTemplate}
              showHistoryTemplates={showHistoryList}
              hideHistoryList={this.showHistoryList}
            />
          )}
          {client?.history.find(pHistory => pHistory.id === currentHistoryId) && (
            <AreaListContainer
              historyTemplate={client?.history.find(pHistory => pHistory.id === currentHistoryId)}
              selectFeature={this.selectFeature}
              showAreaList={showAreaList}
              hideAreaList={this.showHistoryList}
            />
          )}
          <Col className="p-0 h-100">
            <MapMenu
              sideMenuExpanded={showSubsidiaryList || showHistoryList || showAreaList}
              transmissionType={client?.transmissionType}
              showSubsidiaryList={showSubsidiaryList}
              showHistoryList={showHistoryList}
              offerOrderSelected={currentHistoryId !== -1}
              centerLocation={this.centerLocation}
              fitSelection={this.fitSelection}
              printSelection={this.printSelection}
              toggleSubsidiaries={this.toggleSubsidiaryList}
              toggleHistory={this.toggleHistoryList}
              exportCSV={this.exportCSV}
              exportExcel={this.exportExcel}
              toggleArea={this.toggleAreaList}
              zoomToAddress={this.zoomToAddress}
            />
            <Infobox selectedArea={selectedArea} />
            <MapComponent
              ref={this.mapComponentRef}
              client={client}
              isFullscreen={isFullscreen}
              currentHistoryId={currentHistoryId}
              changeFullscreen={this.changeFullscreen}
              enableLoadingOverlay={this.enableLoadingOverlay}
              selectFeature={this.selectFeature}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
