import * as React from 'react';

import { ListGroup } from 'react-bootstrap';
import { map } from 'lodash';

import SubsidiaryListItem from './SubsidiaryListItem';
import { sortClientLocations } from '../../util/sortUtil';
import { SubsidiaryListProps } from '../../@types/Subsidiary.d';
import { SUBSIDIARY_LIST_EMPTY } from '../../constants/labels';

const SubsidiaryList: React.FC<SubsidiaryListProps> = (props: SubsidiaryListProps) => {
  const { subsidiaries, zoomToSubsidiary } = props;
  return (
    <div className="list-container">
      <ListGroup className="flex-list">
        {subsidiaries.length > 0 ? (
          map(sortClientLocations(subsidiaries), subsidiary => (
            <SubsidiaryListItem
              key={subsidiary.id}
              subsidiary={subsidiary}
              zoomToSubsidiary={zoomToSubsidiary}
            />
          ))
        ) : (
          <ListGroup.Item>{SUBSIDIARY_LIST_EMPTY}</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

export default SubsidiaryList;
