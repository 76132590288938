import '../../styles/Map.scss';

import * as React from 'react';

import { MapBrowserEvent, Feature, Overlay } from 'ol';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import { getCenter, createEmpty, extend } from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { defaults as defaultControls, Control, FullScreen, ScaleLine } from 'ol/control';
import { defaults as defaultInteractions } from 'ol/interaction';
import { transform as projTransform } from 'ol/proj';
import OverlayPositioning from 'ol/OverlayPositioning';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import BaseLayer from 'ol/layer/Base';
import Event from 'ol/events/Event';
import Point from 'ol/geom/Point';

import { FeatureLike } from 'ol/Feature';
import { MapProvider, MapComponent as ReactMapComponent, mappify } from '@terrestris/react-geo';
import { filter, compact, reduce } from 'lodash';

import State from 'ol/source/State';
import { generateBaseLayerStyle, generateFeatureStyle } from '../../util/featureStyle';
import { getLocationFeature, getLocationFeatures } from '../../util/featureUtil';

import {
  LAYER_FIELD_TITLE,
  LAYER_TITLE_TILES,
  COORD_FREYPLUS,
  COORD_EPSG_4326,
  COORD_EPSG_3857,
  DEFAULT_FEATURE_COLOR_STYLE,
  LAYER_TYPE_POSTCODE,
  LAYER_FIELD_TYPE,
  LAYER_FIELD_COUNTRY_CODE,
  REQUEST_IDENTIFIER_INSERT_META_DATA,
  REQUEST_IDENTIFIER_REMOVE_META_DATA,
  LAYER_TITLE_LOCATIONS,
  FEATURE_FIELD_AREA_KEY,
  FEATURE_FIELD_AREA_REF,
  FEATURE_FIELD_SUBSIDIARY_NAME,
  FEATURE_FIELD_SUBSIDIARY_STREET,
  FEATURE_FIELD_SUBSIDIARY_HOUSENUMBER,
  FEATURE_FIELD_SUBSIDIARY_POSTCODE,
  FEATURE_FIELD_SUBSIDIARY_CITY,
  FEATURE_FIELD_TYPE,
  FEATURE_TYPE_LOCATION,
  REQUEST_IDENTIFIER_LOAD_LAYER,
  MAP_MAX_ZOOM,
  MAP_MIN_ZOOM,
  MAP_ZOOM_POSTCODE,
  MAP_ZOOM_DISTRICT
} from '../../constants/constants';
import { URL_OSM_SERVER, URL_GEOSERVER } from '../../constants/network';
import {
  MAP_OVERLAY_CLIENT_LOCATION_NAME,
  MAP_OVERLAY_CLIENT_LOCATION_STREET,
  MAP_OVERLAY_CLIENT_LOCATION_POSTCODE,
  MAP_OVERLAY_CLIENT_LOCATION_PLACE,
  MAP_OVERLAY_AREA_NAME,
  MAP_OVERLAY_POSTCODE,
  MAP_OVERLAY_AREA_CIRCULATION
} from '../../constants/labels';
import config from '../../config';

import { MapComponentProps, MapComponentState } from '../../@types/MapComponent.d';
import {
  ClientLayer,
  ClientLocation,
  Coordinates,
  OfferHistoryTemplate,
  OrderHistoryTemplate,
  Area,
  DistributionTemplateLocation,
  LayerType
} from '../../@types/Common.d';

const MappifiedMap = mappify(ReactMapComponent);
export const LAYER_DEFAULT = {
  layerColor: DEFAULT_FEATURE_COLOR_STYLE,
  countryCode: 'de',
  state: '',
  type: LAYER_TYPE_POSTCODE,
  title: 'DE_POSTCODE'
} as ClientLayer;

export default class MapComponent extends React.Component<MapComponentProps, MapComponentState> {
  private mapOverlay: Overlay | null = null;

  constructor(props: MapComponentProps) {
    super(props);

    this.initMap = this.initMap.bind(this);

    this.state = {
      olMap: this.initMap(),
      layersTotal: 1,
      currentLayerType: 'POSTCODE',
      layersLoaded: new Map<string, ClientLayer>()
    };

    this.adjustMapSize = this.adjustMapSize.bind(this);
    this.appendAreaLayersToMap = this.appendAreaLayersToMap.bind(this);
    this.appendAreaLayerToMap = this.appendAreaLayerToMap.bind(this);
    this.appendLocationLayerToMap = this.appendLocationLayerToMap.bind(this);
    this.appendLocationToMap = this.appendLocationToMap.bind(this);
    this.findAreaKeyOnMap = this.findAreaKeyOnMap.bind(this);
    this.findAreaKeysOnMap = this.findAreaKeysOnMap.bind(this);
    this.finishLayerLoading = this.finishLayerLoading.bind(this);
    this.fitHistoryFeatures = this.fitHistoryFeatures.bind(this);
    this.fitFeatures = this.fitFeatures.bind(this);
    this.getLocationsLayer = this.getLocationsLayer.bind(this);
    this.initMap = this.initMap.bind(this);
    this.insertMetaData = this.insertMetaData.bind(this);
    this.onClickMap = this.onClickMap.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.removeMetaData = this.removeMetaData.bind(this);
    this.showToolTip = this.showToolTip.bind(this);
    this.zoomToArea = this.zoomToArea.bind(this);
    this.zoomToCoordinates = this.zoomToCoordinates.bind(this);
    this.zoomToMarker = this.zoomToMarker.bind(this);

    // this.appendAreaLayerToMap(LAYER_DEFAULT);
  }

  componentDidUpdate(prevProps: MapComponentProps) {
    const { olMap, layersLoaded } = this.state;
    const { client, currentHistoryId } = this.props;

    if (client !== prevProps.client || currentHistoryId !== prevProps.currentHistoryId) {
      Array.from(layersLoaded.values()).forEach(layer => {
        const { layer: vLayer } = layer;
        if (vLayer) olMap.removeLayer(vLayer);
      });

      const locationLayer = olMap
        .getLayers()
        .getArray()
        .find(layer => layer.get(LAYER_FIELD_TITLE) === LAYER_TITLE_LOCATIONS);
      if (locationLayer) olMap.removeLayer(locationLayer);

      if (client) {
        const currentLayerType =
          client?.history.find(history => history.id === currentHistoryId)?.locations[0].areas[0]
            .type ?? 'POSTCODE';

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          {
            layersTotal: client.clientLayers.filter(layer => layer.type === currentLayerType)
              .length,
            layersLoaded: new Map<string, ClientLayer>(),
            currentLayerType
          },
          () => {
            this.appendAreaLayersToMap(client.clientLayers);
            this.appendLocationLayerToMap(client.clientLocations);
          }
        );
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          {
            layersTotal: 1
          },
          () => {
            this.appendAreaLayerToMap(LAYER_DEFAULT);
          }
        );
      }
    }
  }

  onClickMap(event: MapBrowserEvent) {
    const { olMap } = this.state;
    const { selectFeature } = this.props;

    olMap.forEachFeatureAtPixel(event.pixel, (feature: FeatureLike, layer: Layer) => {
      if (layer instanceof VectorLayer && feature instanceof Feature) {
        if (
          feature.get(FEATURE_FIELD_TYPE) !== FEATURE_TYPE_LOCATION &&
          feature.get(FEATURE_FIELD_AREA_REF)
        ) {
          selectFeature(feature.get(FEATURE_FIELD_AREA_REF));
        } else if (feature.get(FEATURE_FIELD_TYPE) === FEATURE_TYPE_LOCATION) {
          this.zoomToMarker(feature);
        }
      }
    });
  }

  getLocationsLayer() {
    const { olMap } = this.state;

    return olMap
      .getLayers()
      .getArray()
      .find((sLayer: BaseLayer) => {
        if (sLayer instanceof VectorLayer)
          return sLayer.get(LAYER_FIELD_TITLE) === LAYER_TITLE_LOCATIONS;
        return false;
      });
  }

  initMap() {
    const tileLayer = new TileLayer({
      source: new OSM({
        url: URL_OSM_SERVER,
        crossOrigin: 'anonymous'
      })
    });
    tileLayer.set(LAYER_FIELD_TITLE, LAYER_TITLE_TILES);

    const controls = defaultControls({ attribution: false, rotate: false });

    const customControls = [] as Control[];
    customControls.push(new ScaleLine());
    if (config.map.buttons.fullscreen) {
      customControls.push(new FullScreen({ source: 'mapFullscreenContainer' }));
    }

    controls.extend(customControls);

    const olMap = new OlMap({
      controls,
      interactions: defaultInteractions({ doubleClickZoom: false }),
      view: new OlView({
        center: projTransform(COORD_FREYPLUS, COORD_EPSG_4326, COORD_EPSG_3857),
        zoom: MAP_ZOOM_POSTCODE,
        maxZoom: MAP_MAX_ZOOM,
        minZoom: MAP_MIN_ZOOM
      }),
      layers: [tileLayer]
      // ...(this.mapOverlay !== null ? { overlay: [this.mapOverlay] } : {})
    });

    olMap.on('click', event => this.onClickMap(event));
    olMap.on('pointermove', event => {
      this.showToolTip(event);
    });
    olMap.once('postrender', () => {
      const popup = document.getElementById('popup');
      if (popup !== null) {
        this.mapOverlay = new Overlay({
          id: 'overlay',
          element: popup,
          autoPan: true,
          autoPanAnimation: { duration: 250 },
          offset: [30, 0],
          positioning: OverlayPositioning.BOTTOM_LEFT
        });

        olMap.addOverlay(this.mapOverlay);
      }

      olMap.updateSize();
    });

    document.addEventListener('fullscreenchange', () => {
      const { changeFullscreen } = this.props;
      changeFullscreen(document.fullscreenElement !== null);

      olMap.updateSize();
    });

    return olMap;
  }

  adjustMapSize() {
    const { olMap } = this.state;

    setTimeout(() => {
      olMap.updateSize();
    }, 600);
  }

  appendAreaLayersToMap(layers: ClientLayer[]) {
    const { currentLayerType } = this.state;

    layers
      .filter(layer => layer.type === currentLayerType)
      .forEach(layer => {
        this.appendAreaLayerToMap(layer);
      });
  }

  appendAreaLayerToMap(layer: ClientLayer) {
    const { enableLoadingOverlay } = this.props;
    const { olMap } = this.state;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOAD_LAYER);
    const vectorSource = new VectorSource({
      url: URL_GEOSERVER(layer.title),
      format: new GeoJSON(),
      overlaps: false
    });

    const vectorLayer = new VectorLayer({
      visible: true,
      source: vectorSource,
      style: feature => generateBaseLayerStyle(feature, layer.layerColor)
    });
    vectorLayer.set(LAYER_FIELD_TITLE, layer.title);
    vectorLayer.set(LAYER_FIELD_TYPE, layer.type);
    vectorLayer.set(LAYER_FIELD_COUNTRY_CODE, layer.countryCode);

    const listener = (): void => {
      // If the sources state is READY, all features have been read
      if (vectorSource.getState() === State.READY) {
        const { layersLoaded } = this.state;
        // Add the layer to the layersLoaded set
        layersLoaded.set(layer.title, { ...layer, ...{ layer: vectorLayer } });
        const lLayer = layersLoaded.get(layer.title);
        // If the layer hasn't been marked as loaded, mark it as loaded
        if (lLayer && !lLayer.loaded) {
          lLayer.loaded = true;
          layersLoaded.set(layer.title, { ...lLayer, ...{ layer: vectorLayer } });
          // Update the state
          this.setState({ layersLoaded }, () => {
            this.finishLayerLoading();
            enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOAD_LAYER);
          });

          // Remove the listener
          vectorSource.un('change', listener);
        }
      }
    };
    vectorSource.on('change', listener);

    olMap.addLayer(vectorLayer);
  }

  appendLocationLayerToMap(locations: ClientLocation[]) {
    const { olMap } = this.state;

    const locationFeatures = getLocationFeatures(locations);

    const locationLayer = new VectorLayer({
      visible: true,
      source: new VectorSource({
        features: locationFeatures
      })
    });
    locationLayer.set('title', LAYER_TITLE_LOCATIONS);

    olMap.addLayer(locationLayer);
  }

  appendLocationToMap(location: ClientLocation) {
    const layer = this.getLocationsLayer();

    if (!layer) return;
    if (layer instanceof VectorLayer) layer.getSource().addFeature(getLocationFeature(location));
  }

  findFeaturesForAreas(areas: Area[]) {
    areas.forEach(area => this.findFeatureForArea(area));
  }

  findFeatureForArea(area: Area) {
    const { feature, layer } = this.findAreaKeyOnMap(area.areaKey);

    if (feature) area.feature = feature;
    if (layer) area.layer = layer;
  }

  findAreaKeysOnMap(areaKeys: string[]) {
    return areaKeys.map(areaKey => this.findAreaKeyOnMap(areaKey));
  }

  findAreaKeyOnMap(areaKey: string) {
    const { olMap } = this.state;
    let foundFeature: Feature | undefined;
    let foundLayer: VectorLayer | undefined;

    olMap.getLayers().forEach((layer: any) => {
      if (layer instanceof VectorLayer && !foundFeature) {
        layer
          .getSource()
          .getFeatures()
          .forEach(feature => {
            if (feature.get(FEATURE_FIELD_AREA_KEY) === areaKey) {
              foundFeature = feature;
              foundLayer = layer;
              return false;
            }
            return true;
          });
      }
    });

    return { feature: foundFeature, layer: foundLayer };
  }

  finishLayerLoading() {
    const { client, currentHistoryId } = this.props;
    const { layersTotal, layersLoaded } = this.state;

    if (Array.from(layersLoaded.values()).filter(val => val.loaded).length === layersTotal) {
      if (client) {
        const history = client.history.find(item => item.id === currentHistoryId);

        this.removeMetaData(history?.locations);
        this.insertMetaData(history, true);
      }
    }
  }

  fitFeatures(features: Feature[]) {
    const { olMap } = this.state;

    if (!features || features.length <= 0) return;

    const featuresExtent = reduce(
      features,
      (acc, feature) => {
        if (feature) {
          const geometry = feature.getGeometry();
          if (geometry) {
            let accExtend = acc;
            accExtend = extend(accExtend, geometry.getExtent());
            return accExtend;
          }
        }

        return acc;
      },
      createEmpty()
    );
    olMap
      .getView()
      .fit(featuresExtent, { padding: [100, 100, 100, 100], maxZoom: MAP_ZOOM_POSTCODE });
  }

  fitHistoryFeatures(historyTemplate?: OfferHistoryTemplate | OrderHistoryTemplate) {
    if (!historyTemplate) return;

    const historyFeatures = historyTemplate.locations.reduce(
      (acc, location) => [...acc, ...compact(location.areas.map(area => area.feature))],
      [] as Feature[]
    );

    if (!historyFeatures) return;

    this.fitFeatures(historyFeatures);
  }

  insertMetaData(
    historyTemplate?: OfferHistoryTemplate | OrderHistoryTemplate,
    fitFeatures?: boolean
  ) {
    const { enableLoadingOverlay } = this.props;

    if (!historyTemplate) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_INSERT_META_DATA);

    // const historyFeatures = this.findAreaKeysOnMap(
    //   areaMetaData.map((areaMeta: AreaMetaData) => areaMeta.areaKey)
    // ).map(item => item.feature);

    const areas = historyTemplate.locations.reduce(
      (acc, location) => [...acc, ...location.areas],
      [] as Area[]
    );

    areas.forEach(area => {
      this.findFeatureForArea(area);
      area.feature?.set(FEATURE_FIELD_AREA_REF, area);
      area.feature?.setStyle(
        generateFeatureStyle(false, area.areaKey, area.areaMetaData.areaStyle)
      );
    });

    if (fitFeatures) this.fitHistoryFeatures(historyTemplate);
    enableLoadingOverlay(false, REQUEST_IDENTIFIER_INSERT_META_DATA);
  }

  removeMetaData(locations?: DistributionTemplateLocation[]) {
    const { enableLoadingOverlay } = this.props;

    if (!locations) return;

    enableLoadingOverlay(true, REQUEST_IDENTIFIER_REMOVE_META_DATA);

    locations
      .flatMap(({ areas }) => areas)
      .forEach(({ areaKey, areaMetaData, feature }) => {
        feature?.setStyle(generateFeatureStyle(false, areaKey ?? '', areaMetaData.areaStyle));
      });

    enableLoadingOverlay(false, REQUEST_IDENTIFIER_REMOVE_META_DATA);
  }

  printSelection(features: Feature[]) {
    // TODO
  }

  showToolTip(event: MapBrowserEvent) {
    const { olMap } = this.state;
    const feature = olMap.forEachFeatureAtPixel(event.pixel, pFeature => pFeature);
    if (this.mapOverlay === null) return;
    if (
      !feature ||
      !(feature instanceof Feature) ||
      (!feature.get(FEATURE_FIELD_AREA_REF) &&
        feature.get(FEATURE_FIELD_TYPE) !== FEATURE_TYPE_LOCATION)
    ) {
      this.mapOverlay.setPosition(undefined);
      return;
    }

    this.mapOverlay.setPosition(event.coordinate);
    const mapOverlayContent = document.getElementById('popup-content');

    if (mapOverlayContent === null) return;

    if (feature.get(FEATURE_FIELD_TYPE) === FEATURE_TYPE_LOCATION) {
      mapOverlayContent.innerHTML = `<b>${MAP_OVERLAY_CLIENT_LOCATION_NAME}:</b>
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_NAME)}
      <br>
      <b>${MAP_OVERLAY_CLIENT_LOCATION_STREET}:</b>
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_STREET)}
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_HOUSENUMBER)}
      <br>
      <b>${MAP_OVERLAY_CLIENT_LOCATION_POSTCODE}/${MAP_OVERLAY_CLIENT_LOCATION_PLACE}:</b>
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_POSTCODE)}
       ${feature.get(FEATURE_FIELD_SUBSIDIARY_CITY)}
      <br>`;
    } else if (feature.get(FEATURE_FIELD_AREA_REF)) {
      const area = feature.get(FEATURE_FIELD_AREA_REF) as Area;

      mapOverlayContent.innerHTML = `<b>${MAP_OVERLAY_AREA_NAME}:</b>
       ${area.areaName}
      <br>
      <b>${MAP_OVERLAY_POSTCODE}:</b>
       ${area.areaKey}
      <br>
      <b>${MAP_OVERLAY_AREA_CIRCULATION}:</b>
       ${area.circulation}
      <br>
      ${Object.keys(area.areaMetaData.info)
        .map(
          (key: string) => `<b>${key}:</b>
       ${area.areaMetaData.info[key]}
      <br>`
        )
        .join('')}`;
    }
  }

  zoomToMarker(feature: Feature) {
    const { olMap } = this.state;
    const geometry = feature.getGeometry();
    if (geometry) {
      const featurePoint = geometry as Point;
      olMap.getView().animate({ center: featurePoint.getCoordinates(), zoom: MAP_ZOOM_POSTCODE });
    }
  }

  zoomToArea(feature: Feature, isDistrict?: boolean) {
    const { olMap } = this.state;

    olMap.getView().animate({
      center: getCenter(feature.getGeometry().getExtent()),
      zoom: isDistrict ? MAP_ZOOM_DISTRICT : MAP_ZOOM_POSTCODE
    });
  }

  zoomToCoordinates(coordinates: Coordinates) {
    const { olMap } = this.state;
    olMap.getView().animate({
      center: projTransform([coordinates.lon, coordinates.lat], COORD_EPSG_4326, COORD_EPSG_3857),
      zoom: MAP_ZOOM_POSTCODE
    });
  }

  render() {
    const { olMap } = this.state;

    return (
      <MapProvider map={olMap}>
        <div id="popup" className="ol-popup">
          <div id="popup-content" className="popup-content" />
        </div>
        <MappifiedMap className="h-100" />
      </MapProvider>
    );
  }
}
