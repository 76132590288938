import * as React from 'react';

import { Row, Col } from 'react-bootstrap';

import {
  MAP_OVERLAY_AREA_NAME,
  MAP_OVERLAY_POSTCODE,
  MAP_OVERLAY_AREA_CIRCULATION,
  CIRCULATION_PIECES_SHORT
} from '../../../constants/labels';

import {
  InfoboxAttributeListProps,
  InfoboxAttributeListItemProps
} from '../../../@types/MapComponent.d';

const InfoboxAttributeList: React.FC<InfoboxAttributeListProps> = (
  props: InfoboxAttributeListProps
) => {
  const { selectedArea } = props;
  const { areaMetaData } = selectedArea;

  return (
    <div className="info-box-list">
      <InfoboxAttributeListItem title={MAP_OVERLAY_AREA_NAME} value={selectedArea.areaName} />
      <InfoboxAttributeListItem title={MAP_OVERLAY_POSTCODE} value={selectedArea.areaKey} />
      <InfoboxAttributeListItem
        title={MAP_OVERLAY_AREA_CIRCULATION}
        value={`${selectedArea.circulation} ${CIRCULATION_PIECES_SHORT}`}
      />
      {areaMetaData.info ? (
        Object.keys(areaMetaData.info).map(key => (
          <InfoboxAttributeListItem key={key} title={key} value={areaMetaData.info[key]} />
        ))
      ) : (
        <div />
      )}
    </div>
  );
};

const InfoboxAttributeListItem: React.FC<InfoboxAttributeListItemProps> = (
  props: InfoboxAttributeListItemProps
) => {
  const { title, value } = props;

  return (
    <Row className="info-box-list-item">
      <Col className="info-box-list-item-title">{title}:</Col>
      <Col className="info-box-list-item-value">{value}</Col>
    </Row>
  );
};

export default InfoboxAttributeList;
