/* eslint-disable react/jsx-indent */
import '../../styles/SubsidiaryList.scss';
import * as React from 'react';

import { map } from 'lodash';

import {
  SubsidiaryListContainerProps,
  SubsidiaryListContainerState
} from '../../@types/Subsidiary.d';
import SubsidiaryList from './SubsidiaryList';
import ListHeader from '../common/listHeader/ListHeader';
import { SUBSIDIARY_LIST_SEARCH_FIELD_HINT, SUBSIDIARY_LIST_TITLE } from '../../constants/labels';

export default class SubsidiaryListContainer extends React.Component<
  SubsidiaryListContainerProps,
  SubsidiaryListContainerState
> {
  constructor(props: SubsidiaryListContainerProps) {
    super(props);

    const { subsidiaries } = this.props;
    this.state = {
      filteredSubsidiaries: subsidiaries,
      searchString: ''
    };

    this.filterSubsidiaryList = this.filterSubsidiaryList.bind(this);
  }

  componentDidUpdate(prevProps: SubsidiaryListContainerProps) {
    const { subsidiaries } = this.props;

    if (
      JSON.stringify(subsidiaries.map(sub => ({ ...sub, ...{ areas: [] } }))) !==
      JSON.stringify(prevProps.subsidiaries.map(sub => ({ ...sub, ...{ areas: [] } })))
    ) {
      const { searchString } = this.state;
      this.filterSubsidiaryList(searchString);
    }
  }

  filterSubsidiaryList(searchString: string) {
    const { subsidiaries } = this.props;
    let filteredSubsidiaries;
    if (searchString.length <= 0) filteredSubsidiaries = subsidiaries;
    else {
      const searchStringRegex = map(searchString.split(''), s => `[${s}]\\w*`).join('');
      const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
      filteredSubsidiaries = subsidiaries.filter(({ name }) => name.match(regex));
    }

    this.setState({ filteredSubsidiaries, searchString });
  }

  render() {
    const { showSubsidiaryList, hideSubsidiaryList, zoomToSubsidiary } = this.props;
    const { filteredSubsidiaries, searchString } = this.state;

    return (
      <div
        className={`collapse-content d-flex flex-column ${
          showSubsidiaryList ? 'shown' : 'hidden'
        } width subsidiary-list-container`}
      >
        {showSubsidiaryList ? (
          <>
            <ListHeader
              hideList={hideSubsidiaryList}
              searchString={searchString}
              filterList={this.filterSubsidiaryList}
              listTitle={SUBSIDIARY_LIST_TITLE}
              searchHint={SUBSIDIARY_LIST_SEARCH_FIELD_HINT}
            />
            ,
            <SubsidiaryList
              subsidiaries={filteredSubsidiaries}
              zoomToSubsidiary={zoomToSubsidiary}
            />
          </>
        ) : null}
      </div>
    );
  }
}
