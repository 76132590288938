import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../../common/CustomTooltip';

import {
  SORT_KEY_COMPLAINTS_TOTAL,
  SORT_KEY_COMPLAINTS_PERCENTAGE,
  SORT_KEY_CIRCULATION,
  SORT_KEY_AREA_NAME,
  SORT_KEY_AREA_KEY,
  AREA_SORT_ATTR_AREA_NAME,
  AREA_SORT_ATTR_AREA_KEY,
  AREA_SORT_ATTR_COMPLAINTS_TOTAL,
  AREA_SORT_ATTR_COMPLAINTS_PERCENTAGE,
  AREA_SORT_ATTR_CIRCULATION
} from '../../../constants/constants';
import {
  TOOLTIP_SORT_COMPLAINTS_TOTAL,
  TOOLTIP_SORT_COMPLAINTS_PERCENTAGE,
  TOOLTIP_SORT_AREA_NAME,
  TOOLTIP_SORT_AREA_KEY,
  TOOLTIP_SORT_CIRCULATION
} from '../../../constants/labels';

import { AreaListSortHeaderProps, AreaListSortHeaderState } from '../../../@types/AreaList.d';

export default class AreaListSortHeader extends React.Component<
  AreaListSortHeaderProps,
  AreaListSortHeaderState
> {
  constructor(props: AreaListSortHeaderProps) {
    super(props);

    this.state = {
      activeSort: SORT_KEY_AREA_KEY,
      sortDesc: false
    };

    this.onClickAreaKey = this.onClickAreaKey.bind(this);
    this.onClickAreaName = this.onClickAreaName.bind(this);
    this.onClickCirculation = this.onClickCirculation.bind(this);
    this.onClickComplaintsPercentage = this.onClickComplaintsPercentage.bind(this);
    this.onClickComplaintsTotal = this.onClickComplaintsTotal.bind(this);
    this.onKeyDownAreaKey = this.onKeyDownAreaKey.bind(this);
    this.onKeyDownAreaName = this.onKeyDownAreaName.bind(this);
    this.onKeyDownCirculation = this.onKeyDownCirculation.bind(this);
    this.onKeyDownComplaintsPercentage = this.onKeyDownComplaintsPercentage.bind(this);
    this.onKeyDownComplaintsTotal = this.onKeyDownComplaintsTotal.bind(this);
    this.sortAreaKey = this.sortAreaKey.bind(this);
    this.sortAreaName = this.sortAreaName.bind(this);
    this.sortCirculation = this.sortCirculation.bind(this);
    this.sortComplaintsPercetage = this.sortComplaintsPercetage.bind(this);
    this.sortComplaintsTotal = this.sortComplaintsTotal.bind(this);
  }

  componentDidUpdate(prevProps: AreaListSortHeaderProps) {
    const { reinit } = this.props;

    if (prevProps.reinit !== reinit) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        activeSort: SORT_KEY_AREA_KEY,
        sortDesc: false
      });
    }
  }

  onClickComplaintsTotal(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();

    this.sortComplaintsTotal();
  }

  onClickComplaintsPercentage(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();

    this.sortComplaintsPercetage();
  }

  onClickCirculation(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();

    this.sortCirculation();
  }

  onClickAreaName(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();

    this.sortAreaName();
  }

  onClickAreaKey(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();

    this.sortAreaKey();
  }

  onKeyDownComplaintsTotal(event: React.KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation();

    if (event.keyCode === 13) this.sortComplaintsTotal();
  }

  onKeyDownComplaintsPercentage(event: React.KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation();

    if (event.keyCode === 13) this.sortComplaintsPercetage();
  }

  onKeyDownCirculation(event: React.KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation();

    if (event.keyCode === 13) this.sortCirculation();
  }

  onKeyDownAreaName(event: React.KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation();

    if (event.keyCode === 13) this.sortAreaName();
  }

  onKeyDownAreaKey(event: React.KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation();

    if (event.keyCode === 13) this.sortAreaKey();
  }

  sortComplaintsTotal() {
    const { sortByAttribute } = this.props;
    const { activeSort, sortDesc } = this.state;

    const nSortDesc = activeSort === SORT_KEY_COMPLAINTS_TOTAL && !sortDesc;

    this.setState({ activeSort: SORT_KEY_COMPLAINTS_TOTAL, sortDesc: nSortDesc }, () => {
      sortByAttribute(AREA_SORT_ATTR_COMPLAINTS_TOTAL, nSortDesc, true);
    });
  }

  sortComplaintsPercetage() {
    const { sortByAttribute } = this.props;
    const { activeSort, sortDesc } = this.state;

    const nSortDesc = activeSort === SORT_KEY_COMPLAINTS_PERCENTAGE && !sortDesc;

    this.setState({ activeSort: SORT_KEY_COMPLAINTS_PERCENTAGE, sortDesc: nSortDesc }, () => {
      sortByAttribute(AREA_SORT_ATTR_COMPLAINTS_PERCENTAGE, nSortDesc, true);
    });
  }

  sortCirculation() {
    const { sortByAttribute } = this.props;
    const { activeSort, sortDesc } = this.state;

    const nSortDesc = activeSort === SORT_KEY_CIRCULATION && !sortDesc;

    this.setState({ activeSort: SORT_KEY_CIRCULATION, sortDesc: nSortDesc }, () => {
      sortByAttribute(AREA_SORT_ATTR_CIRCULATION, nSortDesc);
    });
  }

  sortAreaName() {
    const { sortByAttribute } = this.props;
    const { activeSort, sortDesc } = this.state;

    const nSortDesc = activeSort === SORT_KEY_AREA_NAME && !sortDesc;

    this.setState({ activeSort: SORT_KEY_AREA_NAME, sortDesc: nSortDesc }, () => {
      sortByAttribute(AREA_SORT_ATTR_AREA_NAME, nSortDesc);
    });
  }

  sortAreaKey() {
    const { sortByAttribute } = this.props;
    const { activeSort, sortDesc } = this.state;

    const nSortDesc = activeSort === SORT_KEY_AREA_KEY && !sortDesc;

    this.setState({ activeSort: SORT_KEY_AREA_KEY, sortDesc: nSortDesc }, () => {
      sortByAttribute(AREA_SORT_ATTR_AREA_KEY, nSortDesc);
    });
  }

  render() {
    const { activeSort, sortDesc } = this.state;

    return (
      <div className="area-list-sort-header">
        <Row className="no-gutters">
          <Col xs={1} className="icon-col">
            <CustomTooltip tooltipId="area-sort-area-key" content={TOOLTIP_SORT_AREA_KEY}>
              <div
                role="button"
                tabIndex={0}
                onClick={this.onClickAreaKey}
                onKeyDown={this.onKeyDownAreaKey}
                className={`hover-icon text-center ${
                  activeSort === SORT_KEY_AREA_KEY ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={activeSort === SORT_KEY_AREA_KEY && sortDesc ? faSortUp : faSortDown}
                />
              </div>
            </CustomTooltip>
          </Col>
          <Col xs={2} />
          <Col xs={1} className="icon-col">
            <CustomTooltip tooltipId="area-sort-area-name" content={TOOLTIP_SORT_AREA_NAME}>
              <div
                role="button"
                tabIndex={-1}
                onClick={this.onClickAreaName}
                onKeyDown={this.onKeyDownAreaName}
                className={`hover-icon text-center ${
                  activeSort === SORT_KEY_AREA_NAME ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={activeSort === SORT_KEY_AREA_NAME && sortDesc ? faSortUp : faSortDown}
                />
              </div>
            </CustomTooltip>
          </Col>
          <Col xs={1} className="icon-col">
            <CustomTooltip tooltipId="area-sort-circulation" content={TOOLTIP_SORT_CIRCULATION}>
              <div
                role="button"
                tabIndex={-2}
                onClick={this.onClickCirculation}
                onKeyDown={this.onKeyDownCirculation}
                className={`hover-icon text-center ${
                  activeSort === SORT_KEY_CIRCULATION ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={activeSort === SORT_KEY_CIRCULATION && sortDesc ? faSortUp : faSortDown}
                />
              </div>
            </CustomTooltip>
          </Col>
          <Col xs={5} />
          <Col xs={1} className="icon-col">
            <CustomTooltip
              tooltipId="area-sort-complaints-total"
              content={TOOLTIP_SORT_COMPLAINTS_TOTAL}
            >
              <div
                role="button"
                tabIndex={-3}
                onClick={this.onClickComplaintsTotal}
                onKeyDown={this.onKeyDownComplaintsTotal}
                className={`hover-icon text-center ${
                  activeSort === SORT_KEY_COMPLAINTS_TOTAL ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={
                    activeSort === SORT_KEY_COMPLAINTS_TOTAL && sortDesc ? faSortUp : faSortDown
                  }
                />
              </div>
            </CustomTooltip>
          </Col>
          <Col xs={1} className="icon-col">
            <CustomTooltip
              tooltipId="area-sort-complaints-percentage"
              content={TOOLTIP_SORT_COMPLAINTS_PERCENTAGE}
            >
              <div
                role="button"
                tabIndex={-4}
                onClick={this.onClickComplaintsPercentage}
                onKeyDown={this.onKeyDownComplaintsPercentage}
                className={`hover-icon text-center ${
                  activeSort === SORT_KEY_COMPLAINTS_PERCENTAGE ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={
                    activeSort === SORT_KEY_COMPLAINTS_PERCENTAGE && sortDesc
                      ? faSortUp
                      : faSortDown
                  }
                />
              </div>
            </CustomTooltip>
          </Col>
        </Row>
      </div>
    );
  }
}
