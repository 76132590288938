const config = {
  general: {
    apiUrl: process.env.REACT_APP_API_URL,
    apiKey: process.env.REACT_APP_API_KEY,
    printToConsole: process.env.REACT_APP_PRINT_TO_CONSOLE === 'true'
  },
  map: {
    buttons: {
      fitSelection: process.env.REACT_APP_FIT_SELECTION === 'true',
      exportSelection: process.env.REACT_APP_EXPORT_SELECTION === 'true',
      fullscreen: process.env.REACT_APP_FULLSCREEN === 'true',
      printMap: process.env.REACT_APP_PRINT_MAP === 'true',
      searchAddress: process.env.REACT_APP_SEARCH_ADDRESS === 'true',
      showSubsidiaries: process.env.REACT_APP_SHOW_SUBSIDIARIES === 'true',
      zoomToCurrentLocation: process.env.REACT_APP_ZOOM_TO_CURRENT_LOCATION === 'true'
    }
  }
};
export default config;
