import * as React from 'react';

import { FormControl, InputGroup } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListSearchProps } from '../../../@types/App.d';

const ListSearch: React.FC<ListSearchProps> = (props: ListSearchProps) => {
  const { filterList, searchHint, searchString } = props;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    filterList(event.currentTarget.value);
  };

  return (
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        className="type-search"
        type="search"
        placeholder={searchHint}
        aria-label={searchHint}
        onChange={onChange}
        value={searchString}
      />
    </InputGroup>
  );
};

export default ListSearch;
