import { ColorStyle, AreaMetaData } from '../@types/Common.d';

export const PLZ_FONT: string = 'bold 11pt Arial, Verdana, Helvetica, sans-serif';
export const MAP_ZOOM_POSTCODE = 12;
export const MAP_ZOOM_DISTRICT = 17;
export const MAP_MAX_ZOOM = 17;
export const MAP_MIN_ZOOM = 7;

export const REQUEST_IDENTIFIER_GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const REQUEST_IDENTIFIER_GET_CLIENT_HISTORY = 'GET_CLIENT_HISTORY';
export const REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM = 'GET_CLIENT_HISTORY_ITEM';
export const REQUEST_IDENTIFIER_INSERT_META_DATA = 'INSERT_META_DATA';
export const REQUEST_IDENTIFIER_REMOVE_META_DATA = 'REMOVE_META_DATA';
export const REQUEST_IDENTIFIER_LOAD_LAYER = 'LOAD_LAYER';

export const LAYER_FIELD_TITLE: string = 'title';
export const LAYER_FIELD_TYPE: string = 'type';
export const LAYER_FIELD_COUNTRY_CODE: string = 'countryCode';

export const LAYER_TITLE_TILES: string = 'tiles';
export const LAYER_TITLE_LOCATIONS: string = 'locations';
export const LAYER_TITLE_ISOCHRONE: string = 'isochrone';

export const LAYER_TYPE_DISTRICT: string = 'DISTRICT';
export const LAYER_TYPE_POSTCODE: string = 'POSTCODE';

export const FEATURE_TYPE_LOCATION: string = 'location';
export const FEATURE_TYPE_AREA: string = 'area';

export const FEATURE_FIELD_SELECTED: string = 'selected';
export const FEATURE_FIELD_AREA_REF: string = 'area';
export const FEATURE_FIELD_NAME: string = 'name';
export const FEATURE_FIELD_AREA_KEY: string = 'areaKey';
export const FEATURE_FIELD_POSTCODE: string = 'postcode';
export const FEATURE_FIELD_STATE: string = 'state';
export const FEATURE_FIELD_TYPE: string = 'type';
export const FEATURE_FIELD_SUBSIDIARY_NAME: string = 'name';
export const FEATURE_FIELD_SUBSIDIARY_PLANABLE: string = 'planable';
export const FEATURE_FIELD_SUBSIDIARY_STREET: string = 'street';
export const FEATURE_FIELD_SUBSIDIARY_HOUSENUMBER: string = 'housenumber';
export const FEATURE_FIELD_SUBSIDIARY_POSTCODE: string = 'postcode';
export const FEATURE_FIELD_SUBSIDIARY_CITY: string = 'city';

export const TRANSMISSION_TYPE_ORDER = 'ORDER';
export const TRANSMISSION_TYPE_OFFER = 'OFFER';

export const COORD_EPSG_4326: string = 'EPSG:4326';
export const COORD_EPSG_3857: string = 'EPSG:3857';

export const COORD_FREYPLUS: number[] = [9.9876076, 48.4010822];

export const WEEKPART_WEEKEND: string = 'WEEKEND';
export const WEEKPART_MIDWEEK: string = 'MIDWEEK';
export const WEEKPART_BEST: string = 'BEST';

export const COUNTRY_CODE_DE = 'DE';
export const COUNTRY_CODE_FR = 'FR';
export const COUNTRY_CODE_CH = 'CH';

export const DEFAULT_STROKE_WIDTH = 1;
export const DEFAULT_STROKE_WIDTH_SELECTED = 3;

export const COMPLAINT_SEVERITY_THRESHOLD_LOW = 33;
export const COMPLAINT_SEVERITY_THRESHOLD_MEDIUM = 66;
export const COMPLAINT_SEVERITY_THRESHOLD_HIGHT = 66;

export const FEATURE_ZINDEX_SELECTED = 100;
export const FEATURE_ZINDEX_NOT_SELECTED = 10;
export const FEATURE_ZINDEX_SUBSIDIARY = 200;

export const SORT_KEY_COMPLAINTS_TOTAL = 'COMPLAINTS_TOTAL';
export const SORT_KEY_COMPLAINTS_PERCENTAGE = 'COMPLAINTS_PERCENTAGE';
export const SORT_KEY_CIRCULATION = 'CIRCULATION';
export const SORT_KEY_AREA_NAME = 'AREA_NAME';
export const SORT_KEY_AREA_KEY = 'AREA_KEY';

export const AREA_SORT_ATTR_COMPLAINTS_TOTAL = 'Reklamationen';
export const AREA_SORT_ATTR_COMPLAINTS_PERCENTAGE = 'Reklamationen (%)';
export const AREA_SORT_ATTR_CIRCULATION = 'circulation';
export const AREA_SORT_ATTR_AREA_NAME = 'areaName';
export const AREA_SORT_ATTR_AREA_KEY = 'areaKey';

export const DEFAULT_FEATURE_COLOR_STYLE = {
  fill: 'rgba(0, 0, 0, 0)',
  fillSelected: 'rgba(38, 50, 56, 0.5)',
  stroke: 'rgba(0, 0, 0, 1.0)',
  strokeSelected: 'rgba(0, 0, 0, 1.0)',
  strokeWidth: DEFAULT_STROKE_WIDTH
} as ColorStyle;

export const DUMMY_COMPLAINT_DATA = (areaKeys: string[]) =>
  areaKeys.map(areaKey => {
    const circulation = Math.floor(Math.random() * 10000);
    const complaints = Math.floor(Math.random() * circulation);
    const percentage = +((complaints / circulation) * 100).toLocaleString('en-us', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    let areaStyle;

    if (percentage < COMPLAINT_SEVERITY_THRESHOLD_LOW)
      areaStyle = {
        fill: 'rgba(28, 235, 45, 0.5)',
        fillSelected: 'rgba(28, 235, 45, 0.5)',
        stroke: 'rgba(28, 235, 45, 1.0)',
        strokeSelected: 'rgba(255, 255, 255, 1.0)',
        strokeWidth: DEFAULT_STROKE_WIDTH,
        strokeWidthSelected: DEFAULT_STROKE_WIDTH_SELECTED
      };
    else if (
      percentage >= COMPLAINT_SEVERITY_THRESHOLD_LOW &&
      percentage < COMPLAINT_SEVERITY_THRESHOLD_HIGHT
    )
      areaStyle = {
        fill: 'rgba(252, 186, 3, 0.5)',
        fillSelected: 'rgba(252, 186, 3, 0.5)',
        stroke: 'rgba(rgba(252, 186, 3, 1.0)',
        strokeSelected: 'rgba(255, 255, 255, 1.0)',
        strokeWidth: DEFAULT_STROKE_WIDTH,
        strokeWidthSelected: DEFAULT_STROKE_WIDTH_SELECTED
      };
    else if (percentage >= COMPLAINT_SEVERITY_THRESHOLD_HIGHT)
      areaStyle = {
        fill: 'rgba(252, 49, 3, 0.5)',
        fillSelected: 'rgba(252, 49, 3, 0.5)',
        stroke: 'rgba(252, 49, 3, 1.0)',
        strokeSelected: 'rgba(255, 255, 255, 1.0)',
        strokeWidth: DEFAULT_STROKE_WIDTH,
        strokeWidthSelected: DEFAULT_STROKE_WIDTH_SELECTED
      };

    return {
      areaKey,
      areaStyle,
      info: {
        complaints: `${complaints}`,
        percentage: `${percentage}`
      }
    } as AreaMetaData;
  }) as AreaMetaData[];
