import { Feature } from 'ol';
import { Icon, Style, Fill, Stroke, Text } from 'ol/style';
import { FeatureLike } from 'ol/Feature';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { ColorLike } from 'ol/colorlike';

import {
  FEATURE_FIELD_AREA_KEY,
  PLZ_FONT,
  DEFAULT_FEATURE_COLOR_STYLE,
  DEFAULT_STROKE_WIDTH,
  DEFAULT_STROKE_WIDTH_SELECTED,
  FEATURE_ZINDEX_SELECTED,
  FEATURE_ZINDEX_NOT_SELECTED,
  FEATURE_ZINDEX_SUBSIDIARY
} from '../constants/constants';
import { ColorStyle } from '../@types/Common.d';

export function rgbTorgba(colorRGB: string, opacity: number = 1) {
  // rgb[0] = r, rgb[1] = g, rgb[2] = b, rgb[3] = a (if present)
  const rgb = colorRGB.match(/\d+|(\.\d*)/g);
  if (rgb !== null) return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
  return colorRGB;
}

const generateFeatureLabel = (name: string) =>
  new Text({
    text: name,
    font: PLZ_FONT,
    stroke: new Stroke({ color: 'rgba(255, 255, 255, 1)', width: 3 })
  });

export function generateFeatureStyle(
  selected: boolean,
  name: string,
  colors: ColorStyle = DEFAULT_FEATURE_COLOR_STYLE
) {
  return new Style({
    fill: new Fill({ color: selected ? colors.fillSelected : colors.fill }),
    stroke: new Stroke({
      color: selected ? colors.strokeSelected : colors.stroke,
      width: selected
        ? colors.strokeWidthSelected ?? DEFAULT_STROKE_WIDTH_SELECTED
        : colors.strokeWidth ?? DEFAULT_STROKE_WIDTH
    }),
    text: generateFeatureLabel(name),
    zIndex: selected ? FEATURE_ZINDEX_SELECTED : FEATURE_ZINDEX_NOT_SELECTED
  });
}

export function generateAdditionalFeatureStyle(
  selected: boolean,
  name: string,
  colors: ColorStyle = DEFAULT_FEATURE_COLOR_STYLE
) {
  return new Style({
    fill: new Fill({
      color: selected
        ? (color => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context === null) return color;

            canvas.width = 10;
            canvas.height = 10;

            context.fillStyle = color;
            context.fillRect(0, 0, canvas.width, canvas.height);

            context.fillStyle = rgbTorgba(color as string, 0.23) as ColorLike;
            context.fillRect(5, 0, 5, 10);
            context.fill();

            const pattern = context.createPattern(canvas, 'repeat');
            if (pattern !== null) return pattern;
            return color;
          })(colors.fillSelected)
        : colors.fill
    }),
    stroke: new Stroke({
      color: selected ? colors.strokeSelected : colors.stroke,
      width: selected
        ? colors.strokeWidthSelected ?? DEFAULT_STROKE_WIDTH_SELECTED
        : colors.strokeWidth ?? DEFAULT_STROKE_WIDTH
    }),
    text: generateFeatureLabel(name),
    zIndex: selected ? FEATURE_ZINDEX_SELECTED : FEATURE_ZINDEX_NOT_SELECTED
  });
}

export function generateMultipleSelectionFeatureStyle(
  selected: boolean,
  name: string,
  colors: ColorStyle[] = [DEFAULT_FEATURE_COLOR_STYLE]
) {
  return new Style({
    fill: new Fill({
      color: selected
        ? ((pColors: ColorStyle[]) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context === null) return pColors[0].fillSelected;

            canvas.width = 5 * colors.length;
            canvas.height = 10;

            pColors.forEach((color: ColorStyle, index: number) => {
              context.fillStyle = rgbTorgba(color.fillSelected as string, 0.3) as ColorLike;
              context.fillRect(5 * index, 0, 5, 10);
            });

            context.fill();

            const pattern = context.createPattern(canvas, 'repeat');
            if (pattern !== null) return pattern;
            return pColors[0].fillSelected;
          })(colors)
        : colors[0].fill
    }),
    stroke: new Stroke({
      color: selected ? colors[0].strokeSelected : colors[0].stroke,
      width: selected
        ? colors[0].strokeWidthSelected ?? DEFAULT_STROKE_WIDTH_SELECTED
        : colors[0].strokeWidth ?? DEFAULT_STROKE_WIDTH
    }),
    text: generateFeatureLabel(name),
    zIndex: selected ? FEATURE_ZINDEX_SELECTED : FEATURE_ZINDEX_NOT_SELECTED
  });
}

export function setFeatureSelected(feature: Feature, colors?: ColorStyle) {
  feature.setStyle(generateFeatureStyle(true, feature.get(FEATURE_FIELD_AREA_KEY), colors));
}

export function setFeatureNotSelected(feature: Feature, colors?: ColorStyle) {
  feature.setStyle(generateFeatureStyle(false, feature.get(FEATURE_FIELD_AREA_KEY), colors));
}

export function setFeatureMultiSelected(feature: Feature, colors?: ColorStyle[]) {
  feature.setStyle(
    generateMultipleSelectionFeatureStyle(true, feature.get(FEATURE_FIELD_AREA_KEY), colors)
  );
}

export function setFeatureMultiNotSelected(feature: Feature, colors?: ColorStyle[]) {
  feature.setStyle(
    generateMultipleSelectionFeatureStyle(false, feature.get(FEATURE_FIELD_AREA_KEY), colors)
  );
}

export function setAdditionalFeatureSelected(feature: Feature, colors?: ColorStyle) {
  feature.setStyle(
    generateAdditionalFeatureStyle(true, feature.get(FEATURE_FIELD_AREA_KEY), colors)
  );
}

export function setAdditionalFeatureNotSelected(feature: Feature, colors?: ColorStyle) {
  feature.setStyle(
    generateAdditionalFeatureStyle(false, feature.get(FEATURE_FIELD_AREA_KEY), colors)
  );
}

export function generateBaseLayerStyle(
  feature: FeatureLike,
  color: ColorStyle = DEFAULT_FEATURE_COLOR_STYLE
) {
  return new Style({
    stroke: new Stroke({
      color: color.stroke,
      width: color.strokeWidth ?? DEFAULT_STROKE_WIDTH
    }),
    fill: new Fill({
      color: color.fill
    }),
    text: generateFeatureLabel(feature.get(FEATURE_FIELD_AREA_KEY)),
    zIndex: FEATURE_ZINDEX_NOT_SELECTED
  });
}

export function generateLocationStyle(poiIcon: string) {
  return new Style({
    image: new Icon({
      anchor: [0.5, 46],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.PIXELS,
      src: poiIcon
    }),
    zIndex: FEATURE_ZINDEX_SUBSIDIARY
  });
}
