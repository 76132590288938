import '../../../styles/Map.scss';

import * as React from 'react';

import { InfoboxProps } from '../../../@types/MapComponent.d';
import InfoboxAttributeList from './InfoboxAttributeList';

const Infobox: React.FC<InfoboxProps> = (props: InfoboxProps) => {
  const { selectedArea } = props;

  return (
    <div className={`info-box ${selectedArea ? '' : 'collapsed'}`}>
      <div className="underlay-background">
        <div className="info-box-content">
          {selectedArea ? <InfoboxAttributeList selectedArea={selectedArea} /> : <div />}
        </div>
      </div>
    </div>
  );
};

export default Infobox;
